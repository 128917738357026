@import "../../styles";

.subtitle {
  color: $text-on-light-secondary;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 28px;
}