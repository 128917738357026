@import '../../../../styles';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);
  background: $neutral-color-white;

  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    color: $text-on-light-primary;

    @media (min-width: $xl) {
      font-size: 45px;
      line-height: 56px;
    }
  }

  .subtitle {
    color: $text-on-light-tertiary;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}