$xs: 0;
$sm: 480px;
$md: 672px;
$lg: 1056px;
$xl: 1312px;
$xxl: 1584px;

$header-height-mobile: 72px;
$header-height: 88px;

$external-header-height: 48px;

$box-shadow-default: 0 4px 36px 0 rgba(0, 0, 0, 0.08);

$appear-default-animation: appear-default .3s ease-in 1 forwards;

:export {
  /* stylelint-disable */
  XS: $xs;
  SM: $sm;
  MD: $md;
  LG: $lg;
  XL: $xl;
  XXL: $xxl;
  /* stylelint-enable */
}