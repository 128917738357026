@import '../../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  width: 100%;
  height: 100%;

  .map-type-select {
    position: absolute;
    top: 17px;
    right: 17px;
    background-color: $primary-color-primary-05;
    border: 1px solid $primary-color-primary-40;
    box-shadow: $box-shadow-default;
    min-height: 32px;

    :hover,
    &:active,
    &:focus,
    &:focus-visible {
      outline: none;

      input,
      fieldset {
        border: none;
        outline: none;
      }
    }

    :global {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      }

      .MuiSelect-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        right: 12px;

        path {
          stroke: $neutral-color-neutral-90;
        }
      }

      .MuiSelect-iconOpen {
        top: 50%;
        height: 24px;
        width: 24px;
        transform: translateY(-50%) rotate(180deg);
      }

      .MuiSelect-select {
        min-height: 0;
        padding: 4px 56px 4px 12px !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

.map-type-select-menu {
  :global {
    .MuiPaper-root {
      border-radius: 0;
      transform: translateY(8px) !important;
      margin: 0 auto;
      background-color: $primary-color-primary-05;
      box-shadow: $box-shadow-default;
      border: 1px solid $primary-color-primary-40;
    }

    .MuiList-root {
      background-color: $primary-color-primary-05;

      li {
        min-height: 0 !important;
        background-color: $primary-color-primary-05;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .MuiButtonBase-root {
        color: $neutral-color-neutral-90;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 4px;
        padding: 0 0 0 16px;

        &:hover {
          background-color: $tertiary-color-tertiary-10;
        }

        &.Mui-selected {
          background-color: $tertiary-color-tertiary-30;
        }
      }
    }
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 48px;
  padding-bottom: 15px;
  gap: 8px;

  svg {
    margin-top: 2px;
    flex-shrink: 0;
    display: inline;
    cursor: pointer;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: $text-on-light-primary;
}

.map {
  flex: 1;
  min-height: 400px;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  animation: appear 4000ms ease-out forwards;
}

.marker {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.marker-1 {
  background-color: $dot-map-color-1;
}

.marker-2 {
  background-color: $dot-map-color-2;
}

.marker-3 {
  background-color: $dot-map-color-3;
}

.marker-4 {
  background-color: $dot-map-color-4;
}

.legend {
  position: absolute;
  bottom: 17px;
  right: 17px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 8px 8px;
  text-align: right;
  background-color: $primary-color-primary-05;
  box-shadow: $box-shadow-default;
  border: 1px solid $primary-color-primary-40;
  margin-left: 17px;
}

.legend-item {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  text-align: right;
  color: $neutral-color-neutral-90;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition:
    opacity 200ms ease-out,
    color 200ms ease-out;
  white-space: nowrap;

  &:hover {
    color: $neutral-color-neutral-50;
  }

  &.hidden {
    opacity: 0.3;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}

.legend-item-1:before {
  background-color: $dot-map-color-1;
}

.legend-item-2:before {
  background-color: $dot-map-color-2;
}

.legend-item-3:before {
  background-color: $dot-map-color-3;
}

.legend-item-4:before {
  background-color: $dot-map-color-4;
}

.legend-item-other:before {
  background-color: $dot-map-color-4;
}

.heatmap-legend {
  position: absolute;
  bottom: 17px;
  left: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  .heatmap-legend-description {
    font-size: 11px;
    font-weight: 400;
    color: $neutral-color-neutral-90;
  }

  .heatmap-legend-scale {
    height: 80px;
    width: 28px;
    background: linear-gradient(180deg,
        rgba(233, 90, 90, 1) 0%,
        rgba(255, 165, 28, 1) 33%,
        rgba(0, 216, 120, 1) 75%,
        rgba(125, 234, 178, 1) 100%);
  }
}

:global {
  .mapboxgl-control-container {
    top: 17px;
    left: 17px;
    position: absolute;
  }

  .mapboxgl-ctrl-group {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    overflow: visible;
  }

  .mapboxgl-ctrl-group:not(:empty) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .mapboxgl-ctrl-bottom-right,
  a.mapboxgl-ctrl-logo {
    display: none;
  }

  .mapboxgl-ctrl-group>button {
    height: 32px;
    width: 32px;
    background-color: $primary-color-primary-05;
    box-shadow: $box-shadow-default;
    border: 1px solid $primary-color-primary-40;
    cursor: pointer;
  }

  .mapboxgl-ctrl-group>button+button {
    margin-top: 4px;
  }

  .mapboxgl-ctrl-group>button:hover {
    background-color: $primary-color-primary-05;
    opacity: 0.9;
  }

  .mapboxgl-ctrl-group>button:active {
    scale: 1.05;
  }

  .mapboxgl-ctrl-zoom-in {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41NDkyIDEwLjA0OTJDMTcuNTQ5MiA5LjcwNDAyIDE3LjI2OTMgOS40MjQyIDE2LjkyNDIgOS40MjQyTDEwLjY3NDIgOS40MjQyTDEwLjY3NDIgMy4xNzQxOUMxMC42NzQyIDIuODI5MDIgMTAuMzk0MyAyLjU0OTE5IDEwLjA0OTIgMi41NDkxOUM5LjcwMzk5IDIuNTQ5MTkgOS40MjQxNiAyLjgyOTAyIDkuNDI0MTYgMy4xNzQxOUw5LjQyNDE2IDkuNDI0MkwzLjE3NDE2IDkuNDI0MTlDMi44Mjg5OCA5LjQyNDE5IDIuNTQ5MTYgOS43MDQwMiAyLjU0OTE2IDEwLjA0OTJDMi41NDkxNiAxMC4zOTQ0IDIuODI4OTggMTAuNjc0MiAzLjE3NDE2IDEwLjY3NDJMOS40MjQxNiAxMC42NzQyTDkuNDI0MTYgMTYuOTI0MkM5LjQyNDE2IDE3LjI2OTQgOS43MDM5OCAxNy41NDkyIDEwLjA0OTIgMTcuNTQ5MkMxMC4zOTQzIDE3LjU0OTIgMTAuNjc0MiAxNy4yNjk0IDEwLjY3NDIgMTYuOTI0MkwxMC42NzQyIDEwLjY3NDJMMTYuOTI0MiAxMC42NzQyQzE3LjI2OTMgMTAuNjc0MiAxNy41NDkyIDEwLjM5NDQgMTcuNTQ5MiAxMC4wNDkyWiIgZmlsbD0iIzE2MTYxNiIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: 54% 54%;
  }

  .mapboxgl-ctrl-zoom-out {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjkyNDIgOS40MjQxOUMxNy4yNjkzIDkuNDI0MTkgMTcuNTQ5MiA5LjcwNDAyIDE3LjU0OTIgMTAuMDQ5MkMxNy41NDkyIDEwLjM5NDQgMTcuMjY5MyAxMC42NzQyIDE2LjkyNDIgMTAuNjc0MkwzLjE3NDE2IDEwLjY3NDJDMi44Mjg5OCAxMC42NzQyIDIuNTQ5MTYgMTAuMzk0NCAyLjU0OTE2IDEwLjA0OTJDMi41NDkxNiA5LjcwNDAyIDIuODI4OTggOS40MjQxOSAzLjE3NDE2IDkuNDI0MTlMMTYuOTI0MiA5LjQyNDE5WiIgZmlsbD0iIzE2MTYxNiIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: 54% 54%;
  }

  .mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
  }
}