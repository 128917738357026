@import '../../styles';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .input-container {
    margin-bottom: 16px;
  }

  .label {
    align-self: flex-start;
    color: $text-on-light-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .submit {
    width: 100%;
    padding: 12px 24px;
    margin-top: 40px;

    @media (min-width: $md) {
      padding: 16px 32px;
    }
  }
}