@import '../../styles';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }
}

.content {
  animation: $appear-default-animation;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 14px;

  @media (min-width: $md) {
    margin: 0 24px;
  }

  .title {
    margin-top: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
    color: $text-on-light-primary;

    @media (min-width: $md) {
      font-size: 45px;
      line-height: 56px;
    }
  }

  .description {
    max-width: 640px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    color: $text-on-light-primary;
    text-align: center;

    @media (min-width: $md) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.header {
  animation: appear .4s ease-in 1 forwards;
  padding: 12px 32px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  min-height: $header-height-mobile;

  @media (min-width: $md) {
    min-height: $header-height;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: end;
  color: $text-on-light-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  .email {
    font-weight: 600;
    color: $text-on-light-primary;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.logo-container {
  gap: 12px;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    gap: 24px;
  }
}

.logo {
  display: flex;
  position: relative;
  flex-shrink: 0;
  visibility: visible;

  svg {
    flex-shrink: 0;
    height: 42px;
    width: 72px;

    @media (min-width: $md) {
      height: 52px;
      width: 90px;
    }
  }
}