@import '../../styles';

.container {

  .title,
  .subtitle {
    white-space: pre-line;
  }

  .title {
    justify-content: center !important;
    text-align: center;
    font-size: 32px !important;
    line-height: 40px !important;

    @media (min-width: $md) {
      font-size: 45px !important;
      line-height: 56px !important;
    }
  }

  .subtitle {
    text-align: left;
    display: block !important;

    a {
      text-decoration: underline;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .form {
    margin-top: 24px;


    .checkbox-container {
      margin-bottom: 24px;

      .checkbox-content {
        display: flex;
        align-items: center;

        .checkbox-label {
          color: $neutral-color-neutral-90;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          a {
            text-decoration: underline;
            color: inherit;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .checkbox-label-secondary {
          color: $neutral-color-neutral-60;
        }

        .checkbox {
          padding: 0 20px 0 0;
          color: $neutral-color-neutral-50;

          &:hover {
            background: transparent !important;
          }

          svg {
            height: 20px;
            width: 20px;
          }

          rect {
            clip-path: inset(0 0 0 0) !important;
            fill: $neutral-color-white !important;
            stroke: $neutral-color-neutral-50;
            rx: 0;
          }

          &:global(.Mui-checked) {
            svg {
              border: 1px solid $neutral-color-neutral-80;
              background-color: $neutral-color-neutral-80;
            }
          }
        }
      }

      .checkbox-error {
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        margin: 3px 0 0 0;
        color: $feedback-color-error;
      }
    }

    .buttons {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .button {
        max-width: 300px;
        width: 100%;
      }
    }
  }

}