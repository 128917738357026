@import '../../styles';

.container {
  height: calc(100vh - $header-height-mobile);
  height: calc(100dvh - $header-height-mobile);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: $md) {
    height: calc(100vh - $header-height);
    height: calc(100dvh - $header-height);
  }

  h1,
  p {
    text-shadow: 0 0 20px $primary-color-primary-90;
    padding: 0 12px;
  }

  .button {
    margin-top: 24px;
    flex-shrink: 0;
    align-self: flex-end;
  }
}
