@import '../../styles';

.card {
  width: calc(100% - 48px);
  max-width: 650px;
  margin: 0 24px;
}

.logo {
  width: 90px;
}

.title,
.subtitle,
.instructions,
.support-info {
  text-align: center;
  margin-top: 16px;

  @media (min-width: $md) {
    margin-top: 24px;
  }
}

.title {
  font-weight: 400;

  @media (min-width: $md) {
    font-weight: 500;
  }
}

.subtitle,
.instructions {
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 28px;
  }
}

.button-container {
  margin-top: 48px;
  text-decoration: none;

  @media (min-width: $md) {
    margin-top: 40px;
  }
}

.support-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: $text-on-dark-tertiary;

  >a {
    color: $text-on-dark-tertiary;

    &:hover,
    &:active,
    &:link {
      color: $text-on-dark-tertiary;
    }
  }
}

.progress {
  circle {
    stroke: $tertiary-color-tertiary-20;
  }
}