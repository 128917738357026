@import "../../../../styles";

.modal {
  padding-top: 20px !important;
}

.rows {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

