@import '../../styles';

.card {
  max-width: 510px;

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
    width: 100%;

    > a {
      width: 50%;
    }

    @media (min-width: $md) {
      gap: 24px;
    }
  }

  .button {
    text-transform: none;
    padding: 12px 24px;
    width: 100%;
    box-shadow: none;

    :global(.MuiTouchRipple-root) {
      display: none;
    }

    @media (min-width: $md) {
      padding: 16px 32px;
    }
  }

  .link {
    flex: 1;
    color: $text-on-light-tertiary;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    &:first-child {
      text-align: right;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .title {
    color: $text-on-light-primary;
    text-align: center;
    font-size: 32px;
    margin-bottom: 16px;

    @media (min-width: $md) {
      margin-bottom: 24px;
      font-size: 45px;
    }
  }

  .subtitle {
    max-width: 224px;
    font-size: 16px;
    margin-bottom: 48px;

    @media (min-width: $md) {
      max-width: 100%;
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .separator {
    width: 1px;
    height: 20px;
    display: inline-block;
    background: $borders-border-02;
  }
}
