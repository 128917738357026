@import '../../styles';

$footer-height: 126px;
$footer-height-mobile: 210px;

.wrapper {
  @media (min-width: $lg) {
    margin-left: -79px;

    &.with-offset {
      margin-left: -256px;
    }
  }
}

.container {
  min-height: calc(100vh - $header-height-mobile - $footer-height-mobile);
  min-height: calc(100dvh - $header-height-mobile - $footer-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height - $footer-height);
    min-height: calc(100dvh - $header-height - $footer-height);
  }

  .loader {
    width: 60px !important;
    height: 60px !important;
  }
}