@import '../../../styles';

.container {
  width: 100%;
  height: 100%;

  .map-type-select {
    position: absolute;
    z-index: 10;
    top: 17px;
    right: 17px;
    background-color: $primary-color-primary-05;
    border: 1px solid $primary-color-primary-40;
    box-shadow: $box-shadow-default;
    min-height: 32px;

    :hover,
    &:active,
    &:focus,
    &:focus-visible {
      outline: none;

      input,
      fieldset {
        border: none;
        outline: none;
      }
    }

    :global {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      }

      .MuiSelect-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        right: 12px;

        path {
          stroke: $neutral-color-neutral-90;
        }
      }

      .MuiSelect-iconOpen {
        top: 50%;
        height: 24px;
        width: 24px;
        transform: translateY(-50%) rotate(180deg);
      }

      .MuiSelect-select {
        min-height: 0;
        padding: 4px 56px 4px 12px !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

.map-type-select-menu {
  :global {
    .MuiPaper-root {
      border-radius: 0;
      transform: translateY(8px) !important;
      margin: 0 auto;
      background-color: $primary-color-primary-05;
      box-shadow: $box-shadow-default;
      border: 1px solid $primary-color-primary-40;
    }

    .MuiList-root {
      background-color: $primary-color-primary-05;

      li {
        min-height: 0 !important;
        background-color: $primary-color-primary-05;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .MuiButtonBase-root {
        color: $neutral-color-neutral-90;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 4px;
        padding: 0 0 0 16px;

        &:hover {
          background-color: $tertiary-color-tertiary-10;
        }

        &.Mui-selected {
          background-color: $tertiary-color-tertiary-30;
        }
      }
    }
  }
}