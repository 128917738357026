@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  30% {
    transform: scale(1.35, 1.35);
  }

  100% {
    transform: scale(1, 1);
  }
}

.header {
  animation: appear .4s ease-in 1 forwards;
  padding: 12px 32px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  min-height: $header-height-mobile;

  @media (min-width: $md) {
    min-height: $header-height;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logo-container {
  gap: 12px;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    gap: 24px;
  }
}

.hamburger-icon {
  visibility: visible;
  margin-left: 16px;

  @media (min-width: $lg) {
    visibility: hidden;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.logo {
  display: flex;
  position: relative;
  flex-shrink: 0;
  visibility: hidden;

  svg {
    flex-shrink: 0;
    height: 42px;
    width: 72px;

    @media (min-width: $md) {
      height: 52px;
      width: 90px;
    }
  }

  &.visible {
    visibility: visible;
  }
}