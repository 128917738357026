@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dashboard-wrapper {
  display: block;
  margin-bottom: 80px;
}

@media print {
  :global(body) {
    display: none;
  }
}

.unlock-button-wrapper {
  width: 100%;
  background-color: rgba($neutral-color-white, 0.95);

  .unlock-button {
    max-width: 1040px;
    padding: 0 16px;
    margin: 0 auto;

    @media (min-width: $md) {
      padding: 0 32px;
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 24px;
      padding: 2px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 9px;
      color: $text-on-light-primary;
      background-color: $surfaces-light-surface-03;
    }
  }
}

.dashboard {
  animation: appear .4s ease-in 1 forwards;
  margin: 0 auto;
  max-width: 1040px;
  padding: 0 16px 20px;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  @media (min-width: $xl) {
    padding: 0;
  }

  .loader-container {
    display: flex;
    align-items: center;
    height: calc(100vh - $header-height-mobile);

    @media (min-width: $md) {
      height: calc(100vh - $header-height);
    }
  }

  .loader {
    width: 60px !important;
    height: 60px !important;
  }
}

.grid-container {
  background: transparent;
}

.icon {
  width: 28px !important;
  height: 28px !important;

  path {
    fill: $neutral-color-white;
  }
}

.big-number {
  padding: 0;
  background: transparent;

  .dark-icon {
    path {
      fill: $surfaces-dark-surface-01;
    }
  }
}

.no-padding {
  padding: 0;
}

.main-container {
  width: 100%;
  column-gap: 8px;
  column-count: 1;
}

.container {
  display: flex;
  flex-direction: column;
}

.card {
  margin-bottom: 8px;
  box-shadow: $box-shadow-default;
  background-color: $neutral-color-white;
}

.empty-state {
  height: calc(100dvh - 306px);

  @media (min-width: $md) {
    height: calc(100dvh - 256px);
  }
}

.overlay {
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1100;
  color: $neutral-color-neutral-05;
  background-color: rgba($primary-color-primary-80, 0.97) !important;

  .overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 470px;
    padding: 0 16px;
    margin: 0 auto;
    text-align: center;
    gap: 16px;
    line-height: 1.5;
    white-space: pre-line;
  }

  .progress {
    circle {
      stroke: $tertiary-color-tertiary-20;
    }
  }
}