@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .switch {
    position: absolute;
    top: 15px;
    right: 80px;
    z-index: 10;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
  width: calc(100% - 140px);
  color: $text-on-light-tertiary;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 24px;
  }
}

.bar-chart-container {
  width: 100%;
  height: 220px;
}

.icon {
  path {
    fill: $icon-on-light-tertiary;
  }
}