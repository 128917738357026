@import '../../../styles';

.container {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $lg) {
    padding: 24px;
  }

  &.success {
    background: rgba(125, 234, 178, 0.85);
  }

  &.warning {
    background: rgba(255, 203, 125, 0.85);
  }

  .heading {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    color: $text-on-light-primary;

    @media (min-width: $md) {
      font-size: 57px;
      line-height: 64px;
    }

    &.hidden {
      filter: blur(8px);
      color: $surfaces-dark-surface-03;
    }
  }

  .subtitle {
    color: $text-on-light-secondary;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 56px;
    height: 56px;
    border-radius: 50%;

    &.success {
      background: $surfaces-colored-surface-01;
    }

    &.warning {
      background: $surfaces-colored-surface-03;
    }
  }
}