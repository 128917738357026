@import '../../../styles';

.reports {
  gap: 24px;
  display: flex;
  max-width: 1040px;
  align-items: stretch;
  flex-direction: column;
  margin: 40px auto 32px;

  @media (min-width: $md) {
    gap: 40px;
  }
}