@import '../../../styles';
@import 'node_modules/spinthatshit/src/loaders';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  padding: 8px 16px 16px;

  @media (min-width: $lg) {
    padding: 8px 24px 16px;
  }
}

.main-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $text-on-light-tertiary;
  padding: 16px 16px 0;

  @media (min-width: $lg) {
    padding: 16px 24px 0;
  }
}

.zoomable-sunburst-chart-container {
  width: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media (min-width: $lg) {
    padding: 48px 40px;
  }
}