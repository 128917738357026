@import '../../../styles';

.tooltip {
  margin-right: 14px !important;

  @media (min-width: $md) {
    margin-right: 44px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0;
  backdrop-filter: blur(5px);
  background-color: $neutral-color-white;
  border: 1px solid $borders-border-02;
  padding: 24px;
  gap: 24px;
  margin-top: 32px;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .details,
  .actions {
    display: flex;
    flex-direction: column;
  }

  .details {
    gap: 8px;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: $text-on-light-primary;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-on-light-secondary;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    @media (min-width: $md) {
      align-items: flex-end;
    }

    .button {
      flex-shrink: 0;
      white-space: nowrap;
      gap: 16px;
    }
  }
}