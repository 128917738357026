@import '../../styles/index';

.title {
  text-align: center;
  margin: 16px 0;

  @media (min-width: $md) {
    margin: 24px 0;
  }
}

.subtitle {
  font-size: 16px;
  max-width: 224px;
  margin-bottom: 24px;

  @media (min-width: $md) {
    max-width: 100%;
    margin-bottom: 40px;
  }
}