@import '../../../styles';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .nested-input {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.header {
  padding: 24px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid $borders-border-03;

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    color: $text-on-light-primary;
  }
}

.dashboards {
  display: block;
  overflow: auto;
  padding-left: 32px;
  height: auto;
  max-height: 210px;

  @media (min-width: $md) {
    height: 210px;
  }
}

.message {
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  padding: 12px 24px 12px 0;
  color: $text-on-light-tertiary;
  transition: all 200ms ease;

  .message-title {
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    color: $text-on-light-tertiary;
    transition: all 200ms ease;

    &:hover {
      cursor: pointer;
    }

    &.active {
      font-weight: 700;
      color: $text-on-light-primary;
    }
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid $borders-border-02;
  }
}

.empty-state {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-on-light-primary;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  border-top: 0.5px solid $borders-border-03;

  .cta {
    height: 40px;
    width: 218px !important;

    @media (min-width: $md) {
      width: 100% !important;
    }

    @media (min-width: $lg) {
      width: 250px !important;
    }
  }
}

.skeleton {
  width: 100%;
  font-size: 1rem;
  border-radius: 0;
}