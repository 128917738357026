@import '../../../styles';

.tabs {
  left: 0;
  top: -64px;
  position: absolute;
  overflow: hidden;
  width: 100%;

  @media (min-width: $md) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: $lg) {
    width: 50%;
  }

  .tabs-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $sm) {
      justify-content: center;
    }
  }

  .tab {
    gap: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    background: transparent;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: $tertiary-color-tertiary-05;
    }

    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: $text-on-light-secondary;
    }

    svg {
      path {
        fill: $tertiary-color-tertiary-30;
      }
    }
  }
}