@import '../../../../styles';

.card {
  gap: 12px;
  border-radius: 0;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  color: $text-on-light-primary;
  border: 1px solid $neutral-color-neutral-20;
  background: linear-gradient(358deg, #F7FAFF -14.21%, #FFF 92%);
  overflow: hidden;

  @media (min-width: $lg) {
    padding: 40px 32px;
    flex-direction: row;
  }

  .highlighted {
    font-weight: 700;
  }

  .image-wrapper {
    position: relative;
    width: 80%;
    margin: 24px 0;

    @media (min-width: $md) {
      width: 40%;
      margin: 0 -40px 0 0;
    }

    .image-primary {
      width: 80%;
      display: block;
      box-shadow: 0 0 28px #0003;

      @media (min-width: $md) {
        object-fit: cover;
        object-position: 0% 0%;
        width: 100%;
        height: 100%;
      }
    }

    .image-secondary {
      position: absolute;
      width: 40%;
      left: auto;
      right: 0%;
      top: 17%;
      box-shadow: 0 4px 4px #0000001f, 0 0 19.4px #0000001f;

      @media (min-width: $md) {
        left: -15%;
      }
    }
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;

    @media (min-width: $md) {
      max-width: 50%;
    }

    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 8px;

      @media (min-width: $lg) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      color: $text-on-light-primary;

      @media (min-width: $md) {
        margin-bottom: 64px;
      }
    }

    .button {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      @media (min-width: $md) {
        width: fit-content;
        margin: 0;
      }
    }
  }
}