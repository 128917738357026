@import '../../../styles';

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    background-position: 400% 50%;
  }

  100% {
    background-position: -400% 50%;
  }
}

.message-body {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  &.start-aligned {
    align-items: start;
  }
}

.skeleton {
  font-size: 1rem;
  border-radius: 0;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  border: 1px solid $primary-color-primary-30;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 16px;
  color: $neutral-color-neutral-90;
  background-color: $neutral-color-white;

  @media (min-width: $md) {
    font-size: 16px;
    line-height: 18px;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: 17px;
    height: 17px;
  }

  @media (min-width: $md) {
    width: 32px;
    height: 32px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-out;
}

.content {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: $neutral-color-neutral-90;
  flex: 1;
  overflow: hidden;

  @media (min-width: $md) {
    font-size: 16px;
    line-height: 24px;
  }

  .caret {
    display: inline-block;
    width: 2px;
    height: 14px;
    margin-left: 4px;
    margin-bottom: -2px;
    background-color: $neutral-color-neutral-90;
    animation: blink 700ms infinite reverse;
  }

  >div {
    display: inline;

    *:last-child {
      display: inline;
    }
  }

  p {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 12px;

    @media (min-width: $md) {
      margin-bottom: 18px;
    }
  }

  a {
    text-decoration: underline;
    color: $neutral-color-neutral-90;
  }

  ul,
  ol {
    padding-left: 24px;
    margin-bottom: 24px;

    @media (min-width: $md) {
      padding-left: 32px;
      margin-bottom: 32px;
    }

    li {
      margin-bottom: 8px;

      &:last-child {
        display: list-item;
      }
    }
  }

  ul {
    list-style: square;
  }

  ol {
    list-style: decimal;
  }

  h1 {
    font-size: 1.4em;
    font-weight: 400;
    text-align: left;
    margin-bottom: 22px;

    @media (min-width: $md) {
      margin-bottom: 24px;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.1em;
    margin-bottom: 12px;

    @media (min-width: $md) {
      margin-bottom: 16px;
    }
  }

  h2 {
    font-size: 1.15em;
  }

  h3 {
    font-size: 1.2em;
  }
}

.status .content {
  color: transparent;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 0) 100%);
  background-size: 400% 400%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: loading 13s linear infinite;
}