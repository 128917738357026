@import '../../../../styles';

.container {
  position: absolute;
  visibility: hidden;
  top: 0;

  &.active {
    position: relative;
    visibility: visible;
    animation: $appear-default-animation;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $neutral-color-neutral-60;
    margin-bottom: 8px;
  }

  .highlighted {
    margin: 0 4px;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.chart {
  height: 390px !important;
}

.note {
  display: flex;
  align-items: center;
  color: $neutral-color-neutral-50;
  font-size: 10px;
  margin-bottom: 24px;

  svg {
    height: 16px;

    path {
      stroke: $neutral-color-neutral-50;
    }
  }
}