@import '../../styles';
@import 'node_modules/spinthatshit/src/loaders';

.loading-overlay {
  position: relative;
  width: 100%;
  height: 100%;

  &.absolute {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    z-index: 1000;

    &.content-width {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .spinner {
    @include loader04($color: $primary-color-primary-80,
      $size: 60px,
      $duration: 1.2s,
      $border-size: 4px);

    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
}