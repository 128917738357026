@import '../../styles';

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  font-family: 'Figtree', sans-serif !important;
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  color: $neutral-color-neutral-90;
  margin-bottom: 24px;
}

.subtitle {
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: $text-on-light-secondary;
  max-width: 480px;
  margin: 0 auto 40px;
}

.forgot-password {
  margin-top: -12px;
  align-self: flex-end;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $neutral-color-neutral-90;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.view-note {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $neutral-color-neutral-80;
}

.view-switch {
  margin-left: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: $neutral-color-neutral-90;

  &:hover {
    text-decoration: none;
  }
}