@import '../../../styles';

.container {
  overflow: auto;
  max-height: 660px;
  height: 100%;
}

.statement:global(.collapsed *) {
  display: inline;
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  color: $primary-color-primary-80;
}

.empty-state {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  color: $text-on-light-primary;
}