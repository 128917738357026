@import '../../../styles';

.container {
  flex-grow: 1;

  >div {
    background: transparent !important;
  }

  .input {
    padding: 0;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
    height: auto;
    letter-spacing: 0;
    color: $text-on-light-primary;

    &:global(.Mui-disabled) {
      opacity: 1;
      color: $neutral-color-black;
      -webkit-text-fill-color: $neutral-color-black;
    }
  }

  fieldset {
    border-color: transparent !important;
  }
}

.actions {
  display: flex;
  gap: 8px;
  line-height: 0;
}

.action-icon {
  width: 18px;
  height: 18px;

  path {
    fill: $neutral-color-neutral-80;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}