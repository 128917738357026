@import '../../styles';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $text-on-dark-tertiary;
}
