@import '../../styles';

.footer {
  animation: $appear-default-animation;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;
  padding: 40px 24px;
  max-width: 1040px;
  margin: 0 auto;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 64px;
  }

  @media (min-width: $xl) {
    padding: 40px 0;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media (min-width: $md) {
      gap: 40px;
      flex-direction: row;
      align-items: center;
    }

    .buttons {
      display: flex;
      gap: 12px;
      flex-direction: row;
    }

    .button {
      display: flex;
      padding: 6px;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      text-decoration: none;
      transition: all 300ms ease-out;
      background-color: $primary-color-primary-05;

      .icon {
        width: 14px;
        height: 14px;

        path {
          fill: $icon-on-light-secondary;
          transition: all 300ms ease-out;
        }
      }

      &:hover {
        cursor: pointer;
        transition: all 300ms ease-out;
        background-color: $tertiary-color-tertiary-40;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: $md) {
      justify-content: flex-end;
      gap: 24px;
    }
  }

  .contact-button {
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-color: $borders-border-02 !important;
  }

  .technical {
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: center;
      gap: 24px;
    }

    .link {
      color: $text-on-light-primary;
      text-decoration: underline;
      transition: all 300ms ease-out;

      &:hover {
        cursor: pointer;
        text-decoration: none;
        transition: all 300ms ease-out;
      }
    }
  }

  .year {
    display: flex;
    align-items: center;
    color: $text-on-light-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}