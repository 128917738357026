@import '../../styles';

.container {
  position: fixed;
  top: $header-height-mobile;
  left: 16px;
  z-index: 1300;
  max-width: calc(100vw - 32px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (min-width: $sm) {
    max-width: 360px;
  }

  @media (min-width: $md) {
    top: unset;
    bottom: 32px;
    left: 32px;
  }
}