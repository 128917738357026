@import '../../../../styles';

.histogram {
  overflow: visible;

  &.hidden {
    filter: blur(4px);
    pointer-events: none;
  }

  .text {
    font-size: 12px;
    fill: $text-on-light-tertiary;
  }

  .tick {
    line {
      stroke: $text-on-light-tertiary;
    }

    text {
      fill: $text-on-light-tertiary;
    }
  }

  .domain {
    stroke: $text-on-light-tertiary;
  }

  .histogram-bar {
    cursor: pointer;
    clip-path: inset(0 0 2px 0);
    color: $text-on-light-tertiary;
  }
}