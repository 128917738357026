@import '../../styles';

.chat {
  position: relative;
  height: 100%;
  color: $neutral-color-neutral-05;
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
  gap: 8px;
  overflow: auto;
  transform: translateZ(0);
  will-change: transform;

  &:after {
    content: '';
    flex-wrap: wrap;
    flex-shrink: 0;
    display: block;
    height: 50px;
    width: 100%;
    background: transparent;

    @media (min-width: $md) {
      height: 100px;
    }
  }

  @media (min-width: $md) {
    gap: 12px;
  }
}