@import '../../styles';

.pie-chart-container {
  width: 100%;
  height: 220px;

  @media (min-width: $lg) {
    width: 50%;
  }
}

.chart-container {
  gap: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }

  .rows {
    gap: 10px;
    width: 100%;
    display: flex;
    overflow: auto;
    max-height: 200px;
    flex-direction: column;

    &.hidden {
      filter: blur(3px);
    }

    @media (min-width: $lg) {
      width: 50%;
    }

    .rows-title {
      font-size: 14px;
      font-weight: 400;
      color: $text-on-light-tertiary;
    }

    .row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      display: flex;
      width: 100%;
      padding-bottom: 8px;

      .label-container {
        max-width: 90%;
        display: flex;
        align-items: center;
      }

      .label {
        color: $text-on-light-tertiary;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }

      .value {
        color: $text-on-light-primary;
        font-size: 12px;
        font-weight: 600;

        &.hidden {
          filter: blur(3px);
        }
      }

      .color {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  &.full-width {
    flex-direction: column;

    .rows {
      width: 100%;

      @media (min-width: $lg) {
        width: 100%;
      }
    }

    .pie-chart-container {
      width: 100%;
    }
  }
}

.pie-chart {
  margin: 0 auto;
}

.pie {
  overflow: visible;
}

.total-value {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}