@import '../../../../styles';

.tooltip-indicator {
  margin-top: 0 !important;
}

.content {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  border-top: 1px solid $borders-border-04;

  .label {
    gap: 8px;
    width: 100%;
    display: flex;
    max-width: 90%;
    align-items: center;
    text-decoration: none;
    color: $text-on-light-tertiary;

    .label-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      &:hover {
        cursor: pointer;
        color: $neutral-color-neutral-80;
      }
    }

    &.disabled {
      opacity: 0.5;

      .label-text:hover {
        color: inherit;
      }
    }
  }

  .arrow-icon-wrapper {
    line-height: 0;
  }

  .arrow-icon {
    width: 18px;
    height: 18px;
    transform: rotate(0deg);
    transition: all 300ms ease-out;
    fill: $neutral-color-neutral-60;

    &.expanded {
      transform: rotate(180deg);
      transition: all 300ms ease-out;
    }

    &:hover {
      cursor: pointer;
      fill: $neutral-color-neutral-40;
    }
  }
}

.skeleton {
  width: 100%;
  font-size: 1rem;
  border-radius: 0;
}

.indicator {
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  background-color: transparent;
  border: 1px solid $tertiary-color-tertiary-40;

  &.filled {
    background-color: $tertiary-color-tertiary-40;
  }
}

.rows {
  .row {
    padding-left: 16px;
  }

  .question {
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 0;
    text-decoration: none;
    color: $text-on-light-secondary;
    border-top: 1px solid $borders-border-02;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}