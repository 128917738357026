@import '../../../styles';

.container {
  position: absolute;
  bottom: 17px;
  left: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  z-index: 10;

  .heatmap-description {
    font-size: 11px;
    font-weight: 400;
    color: $neutral-color-neutral-90;
  }

  .heatmap-scale {
    height: 80px;
    width: 28px;
    background: linear-gradient(180deg,
        rgba(233, 90, 90, 1) 0%,
        rgba(255, 165, 28, 1) 33%,
        rgba(0, 216, 120, 1) 75%,
        rgba(125, 234, 178, 1) 100%);
  }
}