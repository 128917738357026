@import '../../../../styles';

.container {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions {
    line-height: 0;
  }

  .action-icon {
    width: 18px;
    height: 18px;

    path {
      fill: $neutral-color-neutral-80;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

.input {
  width: 80%;
}