@import '../../styles';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .input-container {
    margin-bottom: 16px;
  }

  .label {
    align-self: flex-start;
    color: $text-on-light-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .submit {
    width: 100%;
    padding: 12px 24px;
    margin-top: 20px;

    @media (min-width: $md) {
      width: auto;
      padding: 16px 32px;
    }
  }
}

.footer-subtitle {
  display: flex;
  flex-direction: column;
  color: $text-on-light-tertiary;
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;

  @media (min-width: $md) {
    display: block;
    margin-top: 16px;
  }
}

.main-link {
  color: $text-on-light-primary;
  padding: 0;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: $md) {
    margin-left: 16px;
  }
}

.title {
  margin-bottom: 16px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}

.subtitle {
  font-size: 16px;
  margin-bottom: 24px;

  @media (min-width: $md) {
    font-size: 18px;
    margin-bottom: 40px;
  }
}