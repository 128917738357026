@import '../../styles';

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: $neutral-color-neutral-80;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  background:
    linear-gradient(135deg, #12e193 0%, #bdda6d 50%, #ffb548 100%),
    lightgray 50% / cover no-repeat;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}