@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
  width: calc(100% - 140px);
  color: $text-on-light-primary;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 24px;
  }
}

.subtitle {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-on-light-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 24px;

  @media (min-width: $md) {
    font-size: 14px;
    line-height: 20px;
  }
}

.histogram-chart-container {
  width: 100%;
  height: 220px;
  overflow: visible;
}