@import '../../styles';

@keyframes appear {
  to {
    transform: scale(1, 1);
  }
}

@keyframes disappear {
  to {
    opacity: 0;
    transform: scale(0, 0);
  }
}

.container {
  position: relative;
  top: 0;
  width: 100%;
  transition: transform 1s ease-out;

  .button {
    transform: scale(0, 0);
    opacity: 0.6;
    width: 48px;
    height: 48px;
    margin: 0;
    background-color: $tertiary-color-tertiary-40;
    border-radius: 0;
    transition:
      opacity 200ms ease-out,
      scale 200ms ease-out;
    animation: appear 200ms ease-out forwards;

    &:hover {
      background-color: $tertiary-color-tertiary-40;
      opacity: 0.8;
    }

    &.disabled {
      cursor: not-allowed;

      &:hover {
        opacity: 0.6;
        background-color: $tertiary-color-tertiary-40;
      }
    }
  }

  .submit {
    width: 15px;
    height: 15px;
  }

  .stop {
    width: 24px;
    height: 24px;

    rect {
      clip-path: none !important;
    }
  }

  .input {
    width: 100%;
    padding: 12px 12px 12px 16px;
    background-color: $neutral-color-white;
    border: 1px solid $primary-color-primary-30;
    border-radius: 0;
    transition: border-color 200ms ease-out;

    @media (min-width: $md) {
      padding-left: 24px;
    }

    &:global(.Mui-focused),
    &.disabled {
      border-color: $tertiary-color-tertiary-10;
    }

    input {
      padding: 16px 0;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      color: $neutral-color-neutral-40;

      @media (min-width: $md) {
        font-size: 16px;
        line-height: 24px;
      }

      &::placeholder {
        color: $neutral-color-neutral-40;
      }

      &:focus,
      &:not(:placeholder-shown) {
        color: $neutral-color-neutral-90;

        &::placeholder {
          opacity: 0;
        }

        +div>.button {
          opacity: 1;
        }
      }

      &[disabled] {
        background-color: initial;
        -webkit-text-fill-color: initial;
        opacity: initial;
        color: $neutral-color-neutral-90;

        &::placeholder {
          opacity: 0.4;
        }
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        caret-color: $neutral-color-neutral-90 !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: $neutral-color-neutral-90 !important;
        color: $neutral-color-neutral-90;
        transition: background-color 5000s ease-in-out 0s, color 5000s;
        box-shadow: inset 0 0 20px 20px $neutral-color-white !important;
      }
    }

    &:hover {
      border-color: $tertiary-color-tertiary-10;

      ::placeholder {
        opacity: 1;
      }
    }

    fieldset,
    &:hover fieldset {
      border: none;
    }
  }

  .progress {
    margin-right: 8px;

    circle {
      stroke: $tertiary-color-tertiary-10;
    }
  }
}