@import '../../styles';

.container {
  width: 100%;

  .divider {
    position: relative;
    margin: 16px 0;
    width: 100%;
    text-align: center;

    span {
      position: relative;
      z-index: 1;
      color: $text-on-light-tertiary;
      background-color: $neutral-color-white;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &::after {
      content: '';
      z-index: 0;
      top: 13px;
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      height: 1px;
      background-color: $borders-border-02;
    }
  }

  .google-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    padding: 11px 24px;
    width: 100%;
    box-shadow: none;
    margin-bottom: 16px;
    gap: 16px;
    color: $text-on-light-primary;
    background-color: $neutral-color-white;
    text-transform: none;
    text-decoration: none;
    transition: border-color 200ms ease-out;
    border: 1px solid $tertiary-color-tertiary-40;

    &:hover,
    &:focus,
    &:active {
      border-color: $tertiary-color-tertiary-30;
    }

    @media (min-width: $md) {
      padding: 15px 32px;
    }
  }
}