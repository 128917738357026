@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  30% {
    transform: scale(1.35, 1.35);
  }

  100% {
    transform: scale(1, 1);
  }
}

.content {
  gap: 16px;
  display: flex;
  align-items: center;

  .title {
    color: $text-on-light-secondary;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }
}

.header {
  top: 0;
  z-index: 2;
  position: sticky;
  margin-bottom: 32px;
  animation: appear .4s ease-in 1 forwards;
  min-height: $external-header-height;
  background: $tertiary-color-tertiary-05;

  @media (min-width: $md) {
    min-height: $external-header-height;
  }

  .header-container {
    padding: 12px 16px;
    display: flex;
    max-width: 1040px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .highlighted {
    font-weight: 700;
  }
}

.logo-container {
  gap: 12px;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    gap: 24px;
  }
}

.action {
  color: $tertiary-color-tertiary-70;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.logo {
  display: flex;
  position: relative;
  flex-shrink: 0;

  svg {
    flex-shrink: 0;
    height: auto;
    width: 42px;
  }
}
