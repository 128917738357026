@import '../../styles';

.see-more {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: $text-on-light-primary;

  @media (min-width: $md) {
    font-size: 16px;
    line-height: 24px;
  }
}

.hidden {
  display: none !important;
}