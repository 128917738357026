@import '../../../styles';

.container {
  position: relative;

  .avatar {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .button {
    position: relative;
    min-width: 0;
    padding: 0;
    margin-left: 16px;
    border: none !important;

    svg {
      width: 24px;
    }
  }
}

.menu-dot {
  position: absolute;
  top: 0;
  right: 0;
}

.paper {
  min-width: 220px !important;
  top: $header-height-mobile !important;
  border-radius: 0 !important;
  background-color: $neutral-color-white !important;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08) !important;

  @media (min-width: $md) {
    top: $header-height !important;
  }

  .menu-item {
    min-height: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: $borders-border-02;
    }

    .icon {
      path {
        fill: $text-on-light-primary;
      }
    }

    .option-button-wrapper {
      padding: 12px;
      width: 100%;

      .option-button {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $neutral-color-black;
      }
    }

    .option-label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 12px;
      border-radius: 0;
      transition: all 300ms ease-out;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-on-light-primary;

      .option-label {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .dot {
          position: absolute;
          top: -2px;
          left: 17px;
        }
      }

      &:hover {
        background-color: $tertiary-color-tertiary-20;
      }

      &.logout {
        color: $red-color-red-20;
      }
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}