@import '../../../styles';

.container {
  display: flex;
  flex-direction: column;
  background-color: $neutral-color-white;
  box-shadow: $box-shadow-default;
  max-width: 400px;
  width: 100%;

  .skeleton {
    font-size: 1rem;
    border-radius: 0;

    &:nth-child(1) {
      width: 93%;
    }

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 95%;
    }

    &:nth-child(4) {
      width: 93%;
    }

    &:nth-child(5) {
      width: 75%;
    }

    &:nth-child(6) {
      width: 92%;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $neutral-color-neutral-80;
    padding: 0 24px;
    margin-bottom: 16px;

    &.loaded {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      line-height: 20px;
      font-size: 14px;
    }
  }

  .title-container {
    gap: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      max-width: 246px;
      color: $text-on-light-primary;
    }

    .download {
      cursor: pointer;
    }
  }

  .report-type {
    display: flex;
    align-items: center;
    padding: 10px 24px 0;
    gap: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $neutral-color-neutral-60;

    .skeleton {
      font-size: 24px;
      width: 30%;
    }
  }

  .image {
    padding: 0 24px;
    height: auto;
    width: 100%;
    margin: 12px 0;
  }

  .footer-container {
    padding: 16px 24px 16px;
    margin-top: auto;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $text-on-light-tertiary;
    border-top: 0.5px solid $borders-border-02;
  }
}