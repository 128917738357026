@import '../../styles';

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  justify-content: center !important;
  text-align: center;
  margin: 24px 0;
}

.subtitle {
  text-align: center;
}

.button {
  display: flex !important;
  margin: 40px auto 0 !important;
}