@import '../../../../styles';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0;
  align-items: flex-start;
  padding: 24px;
  background: $surfaces-light-surface-01;
  color: $text-on-light-primary;
  width: 100%;
  border: 1px solid $neutral-color-neutral-20;

  @media (min-width: $lg) {
    width: 50%;
    padding: 40px 32px;
    border-radius: 0;
  }

  .description-container {
    height: auto;
    margin-bottom: 24px;

    @media (min-width: $lg) {
      height: 220px;
    }

    @media (min-width: $xl) {
      margin-bottom: 0;
    }
  }

  .description,
  .subdescription {
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    color: $text-on-light-tertiary;
    margin-bottom: 8px;
  }

  .subdescription {
    white-space: pre-line;
    color: $text-on-light-primary;
  }

  .header-container {
    display: flex;
    font-weight: 400;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    justify-content: space-between;

    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 8px;

      @media (min-width: $lg) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .price-container {
      display: flex;
      align-items: baseline;
      flex-direction: row;
    }

    .price {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      margin-left: 8px;

      @media (min-width: $lg) {
        font-size: 32px;
        line-height: 40px;
        margin-left: 16px;
      }
    }

    .cycle {
      margin-left: 4px;
      font-size: 16px;
      line-height: 24px;
      color: $text-on-light-tertiary;
      white-space: nowrap;

      @media (min-width: $lg) {
        margin-left: 6px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .highlighted {
    color: $tertiary-color-tertiary-30;
  }

  .feature {
    color: $text-on-light-primary;
    margin-bottom: 16px;
    white-space: pre-line;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: $lg) {
      margin-bottom: 16px;
    }

    &-icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  .button-container {
    width: 100%;
  }

  .button {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px;
    margin-top: 32px;
  }
}