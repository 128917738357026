@import '../../styles';

.title {
  text-align: center;
  margin-bottom: 24px;
  font-family: 'Figtree', sans-serif !important;

  @media (min-width: $md) {
    margin-bottom: 40px;
  }
}

.container {
  width: 100%;

  .input-container {
    margin-bottom: 24px !important;
  }

  .label {
    align-self: flex-start;
    color: $neutral-color-neutral-90;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .actions {
    display: flex;
    margin-top: 24px;
    justify-content: center;
  }

  .submit {
    width: 100%;

    @media (min-width: $md) {
      width: auto;
    }
  }
}