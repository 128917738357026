@import '../../../../styles';

.container {
  position: absolute;
  visibility: hidden;
  top: 0;

  &.active {
    position: relative;
    visibility: visible;
    animation: $appear-default-animation;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $neutral-color-neutral-60;
    margin-bottom: 8px;
  }

  .treemap {
    margin-bottom: 24px;
    overflow: hidden;
  }
}