@import '../../styles';

.container {
  .title {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .description {
    text-align: left;
  }

  .button {
    display: block;
    margin-top: 40px;
    width: 100%;

    @media (min-width: $md) {
      margin: 40px auto 0;
      width: fit-content;
    }
  }
}