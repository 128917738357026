@import "../../../../../styles";

.container {
  gap: 4px;
  display: flex;
  align-items: center;
}

.title {
  max-width: 145px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.arrow-icon {
  width: 20px !important;
  height: 20px !important;
  transform: rotate(0deg);
  transition: all 300ms ease-out;
  fill: $icon-on-light-tertiary;

  &.expanded {
    transform: rotate(180deg);
    transition: all 300ms ease-out;
  }
}