@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .empty-state {
    min-height: 200px;
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: $text-on-light-tertiary;
}

.treemap {
  width: 100%;
  height: 740px;
  border-radius: 0;
  overflow: hidden;

  text {
    stroke: transparent;
    font-weight: 500;
    fill: $primary-color-primary-85;
  }
}

.heading {
  display: flex;
  align-items: center;
  margin-right: 48px;
  justify-content: space-between;
}

.info-rows {
  width: 100%;
  gap: 12px;
  display: flex;
  flex-flow: row wrap;
  margin: 12px 0;

  .row {
    width: 48%;
    gap: 8px;
    display: flex;
    flex-direction: column;

    @media (min-width: $lg) {
      width: 25%;
    }

    .label {
      display: inline-block;
      flex-shrink: 0;
      font-size: 14px;
      color: $text-on-light-tertiary;

      @media (min-width: $md) {
        width: 120px;
      }
    }

    .value {
      font-size: 18px;
      display: inline-block;
      color: $text-on-light-secondary;
    }
  }

  @media (min-width: $md) {
    margin: 24px 0;
  }
}

.treemap,
.info-rows {
  &.hidden {
    filter: blur(3px);
    pointer-events: none;
  }
}