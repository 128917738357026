@import '../../../styles';

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 0.7;
  }

  50% {
    transform: scale(3, 3);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.marker {
  position: relative;
  border-radius: 100px;
  background-color: $secondary-color-secondary-60;
  width: 6px;
  height: 6px;
  border: 1.5px solid $secondary-color-secondary-30;
  box-sizing: content-box;
  cursor: pointer;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: block;
    border-radius: 100px;
    animation: pulsate linear 2s infinite forwards;
    background-color: $secondary-color-secondary-60;
  }

  .popup {
    display: none;
    position: absolute;
    bottom: 3px;
    left: 3px;
    z-index: 2;
    background-color: $primary-color-primary-05;
    border: 0.5px solid $primary-color-primary-30;
    box-shadow: $box-shadow-default;
    padding: 8px;
    gap: 4px;
    color: $neutral-color-neutral-90;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    .popup-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }

    .popup-address {
      display: flex;
      gap: 4px;
    }

    .popup-note {
      color: $neutral-color-neutral-60;

      u {
        cursor: pointer;
        color: $neutral-color-neutral-90;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.active .popup {
    display: flex;
    flex-direction: column;
    width: max-content;
    max-width: 320px;
  }
}

.modal {
  padding: 32px 40px !important;
}

.modal-content>div {
  overflow: hidden !important;
}