@import "../../../../../styles";

.cells {
  gap: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.row {
  width: 100%;
  padding: 17px 0;
  border-bottom: 1px solid $borders-border-03;
}

.content {
  gap: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cell {
  width: 65px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  @media (min-width: $md) {
    width: 140px;
  }
}

.cell-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $text-on-light-primary;

  &.hidden {
    filter: blur(4px);
  }
}

.cell-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-on-light-tertiary;
}

.icon {
  path {
    fill: $icon-on-light-secondary;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.section {
  margin-top: 40px;
  gap: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .title {
    color: $text-on-light-primary;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .expand-rows {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .expand-row {
      gap: 8px;
      display: flex;
      width: 100%;
      padding-bottom: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $borders-border-03;
      }

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 160px;
        color: $text-on-light-tertiary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;

        @media (min-width: $md) {
          min-width: 220px;
        }
      }

      .value {
        color: $text-on-light-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 340px;
        word-break: break-word;
      }
    }
  }
}