@import '../../styles';

.content {
  height: 100%;
  margin-left: 0;
  transition: all 300ms ease-out;

  @media (min-width: $lg) {
    margin-left: 79px;
  }

  &.expanded {
    margin-left: 0;
    transition: all 300ms ease-out;

    @media (min-width: $lg) {
      margin-left: 256px;
    }
  }
}