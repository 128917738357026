@import '../../../../styles';

.send {
  display: none;

  @media (min-width: $md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $primary-color-primary-80;
    transition: opacity 200ms ease-out;
  }
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  background-color: $neutral-color-white;
  border: 1px solid $neutral-color-white;
  color: $neutral-color-neutral-80;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  word-break: break-word;
  box-shadow: $box-shadow-default;
  transition: border-color 200ms ease-out;
  cursor: pointer;

  &:hover {
    border-color: $tertiary-color-tertiary-10;
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;

    &:hover {
      color: $neutral-color-neutral-30;

      .send {
        opacity: 0;
      }
    }
  }
}