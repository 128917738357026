@import '../../styles';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    margin-bottom: 16px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
    color: $text-on-dark-tertiary;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    color: $text-on-dark-tertiary;
  }
}
