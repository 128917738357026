@import '../../../../styles';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .filter {
      padding: 12px;
      flex-grow: 1;
      min-width: 0;
      box-shadow: $box-shadow-default;
      border: 1px solid $neutral-color-white;

      .filter-value {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        width: 100%;
        color: $neutral-color-neutral-40;

        &.has-value {
          color: $text-on-light-primary;
        }
      }
    }
  }

  .note {
    display: flex;
    align-items: center;
    color: $neutral-color-neutral-50;
    font-size: 10px;

    svg {
      height: 16px;

      path {
        stroke: $neutral-color-neutral-50;
      }
    }
  }
}