@import '../../styles';

.modal11 {
  max-width: 760px !important;
}

.title {
  text-align: center;
  white-space: pre-line;
  justify-content: center !important;
}

.subtitle {
  margin: 24px 0;
  color: $text-on-light-secondary;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.button {
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.icons {
  display: flex;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;

  .icon {
    width: 64px;
    height: 64px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

.chips {
  display: flex;
  padding: 0 0 24px 0;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.label {
  align-self: flex-start;
  color: $text-on-light-secondary;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
}

.container {
  width: 100%;

  .input {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 !important;
  }
}