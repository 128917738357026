@import '../../../styles';

.container {
  .heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
    color: $neutral-color-neutral-80;
  }

  .placeholder {
    color: $neutral-color-neutral-30;
  }

  .error {
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    margin: 3px 14px 0 14px;
    color: $feedback-color-error;
  }

  .input-container {
    margin-bottom: 24px !important;
  }

  .label {
    align-self: flex-start;
    color: $text-on-light-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .submit {
    width: 100%;
  }

  .button-container {
    display: inline-block;
    width: 100%;
    margin-top: 48px;

    @media (min-width: $md) {
      width: auto;
    }
  }
}

.menu {
  :global {
    .MuiPaper-root {
      margin: 0 auto;
      border-radius: 0 !important;
      background-color: $primary-color-primary-10;
      box-shadow: none;

      @media (min-width: $md) {
        width: 100%;
        max-width: 240px !important;
      }

      .MuiList-root {
        padding: 12px 0;
        background-color: $primary-color-primary-10;

        li {
          min-height: 0 !important;
          background-color: $primary-color-primary-10;
        }

        .MuiButtonBase-root {
          color: $text-on-light-secondary;
          padding: 8px 0 8px 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background-color: $tertiary-color-tertiary-10;
          }

          &.Mui-selected {
            background-color: $tertiary-color-tertiary-40;
          }
        }
      }
    }
  }
}