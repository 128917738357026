@import '../../../styles';

.container {
  gap: 12px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  &.end-aligned {
    margin-top: 0;
    justify-content: end;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (min-width: $lg) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &.hidden {
      visibility: visible;
      display: none;

      @media (min-width: $md) {
        display: flex;
        visibility: hidden;
        pointer-events: none;
      }
    }

    .action-button {
      margin-right: 0;
      width: 100%;
      height: 40px;
      padding: 10px 24px;
      font-size: 14px 20px;
      font-weight: 500;

      @media (min-width: $lg) {
        width: auto;
        margin-right: 10px;
      }
    }
  }

  .additional-actions {
    gap: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $lg) {
      gap: 32px;
      flex-direction: row;
    }
  }

  .regenerate-container,
  .rate-container {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;

    .icon {
      path {
        fill: $icon-on-light-tertiary;
      }
    }

    .label {
      flex-shrink: 0;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $text-on-light-tertiary;
    }
  }

  .rate-container {
    margin-right: 0;

    .rate-container-button {
      &.disabled {
        opacity: 0.5;

        &:hover,
        &:active,
        &:focus {
          cursor: default;
          background: transparent !important;
        }
      }
    }

    @media (min-width: $lg) {
      margin-right: 16px;
    }
  }

  @media (min-width: $lg) {
    flex-direction: row;
  }
}