@import '../../../styles';
@import 'node_modules/spinthatshit/src/loaders';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  & *:focus {
    outline: none;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $text-on-light-tertiary;
  margin-right: 48px;
}

.note {
  display: flex;
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 24px;
  align-items: center;

  color: $neutral-color-neutral-50;

  svg {
    height: 16px;

    path {
      stroke: $neutral-color-neutral-50;
    }
  }
}