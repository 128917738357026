@import "../../../../styles";

.title {
  overflow: hidden;
  color: $text-on-light-tertiary;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.container {
  display: flex;
  align-items: center;

  .color {
    width: 12px;
    height: 10px;
    display: inline-block;
    margin-right: 4px;
    border-radius: 4px;
  }

  .subtitle {
    color: $text-on-light-secondary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

