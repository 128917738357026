@import '../../../../styles';

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: visible;
  background-color: $neutral-color-white;
  box-shadow: $box-shadow-default;
  margin-bottom: 32px;

  @media (min-width: $lg) {
    overflow: hidden;
    margin-bottom: 0;
  }

  .empty-state {
    position: absolute;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%, calc(-50% + 40px));
    box-sizing: border-box;
    animation: $appear-default-animation;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px;
    background-color: $neutral-color-white;

    .empty-state-title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: $neutral-color-neutral-80;
    }

    .empty-state-description {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $neutral-color-neutral-90;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $primary-color-primary-30;
    padding: 16px 24px;

    .pagination-rows-per-page,
    .pagination-items-from-total {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $neutral-color-neutral-60;

      .pagination-rows-per-page-value {
        margin-left: 8px;
        line-height: 18px;
        color: $neutral-color-neutral-90;
      }
    }
  }

  :global {
    .MuiTable-root {
      background-color: $neutral-color-white;
    }

    .MuiTableContainer-root {
      position: relative;
      height: 100%;
    }

    .MuiTableCell-head {
      padding: 10px 24px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $neutral-color-neutral-60;
      border: none;
    }

    .MuiTableRow-root {
      cursor: pointer;

      &:nth-of-type(odd) {
        background-color: $primary-color-primary-05;
      }
    }

    .MuiTableRow-hover {
      &:hover {
        opacity: 0.6;
        background-color: $primary-color-primary-05 !important;
      }
    }


    .MuiTableCell-body {
      padding: 12px 24px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $neutral-color-neutral-90;
      border: none;
    }

    .MuiPagination-ul {
      .MuiPaginationItem-root {
        padding: 0;
        min-width: 24px;
        height: 24px;
        width: 24px;
        border-radius: 0;
        margin: 0 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: $neutral-color-neutral-60;
        transition: opacity .3s ease, color .3s ease;

        .MuiPaginationItem-icon {
          fill: $neutral-color-neutral-90;
        }

        .MuiTouchRipple-root {
          display: none;
        }

        &:hover {
          opacity: 0.5;
          background-color: transparent;
        }

        &.Mui-selected {
          background-color: transparent;
          border-radius: 0;
          border: 1px solid $tertiary-color-tertiary-40;
          color: $neutral-color-neutral-90;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}