@import '../../styles';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .input-container {
    margin-bottom: 16px;
  }

  .label {
    align-self: flex-start;
    color: $neutral-color-neutral-90;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .checkbox-container {
    align-self: flex-start;
    margin-bottom: 24px;

    .checkbox-content {
      display: flex;
      align-items: center;

      .checkbox-label {
        color: $neutral-color-neutral-90;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        a {
          text-decoration: underline;
          color: inherit;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .checkbox-label-secondary {
        color: $neutral-color-neutral-60;
      }

      .checkbox {
        padding: 0 20px 0 0;
        color: $neutral-color-neutral-50;

        &:hover {
          background: transparent !important;
        }

        svg {
          height: 20px;
          width: 20px;
        }

        rect {
          clip-path: inset(0 0 0 0) !important;
          fill: $neutral-color-white !important;
          stroke: $neutral-color-neutral-50;
          rx: 0;
        }

        &:global(.Mui-checked) {
          svg {
            border: 1px solid $neutral-color-neutral-80;
            background-color: $neutral-color-neutral-80;
          }
        }
      }
    }

    .checkbox-error {
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      margin: 3px 0 0 0;
      color: $feedback-color-error;
    }
  }

  .submit {
    width: 100%;
    padding: 12px 24px;
    margin-top: 20px;

    @media (min-width: $md) {
      width: auto;
      padding: 16px 32px;
    }
  }
}