@import '../../../../styles';

.heading {
  width: calc(100% - 140px);
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $text-on-light-tertiary;
  }

  @media (max-width: 500px) {
    gap: 12px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.table-wrapper {
  margin-top: 16px;
  overflow: visible;

  .table {
    border-radius: 0 !important;
    min-height: 500px;
    max-height: 780px;
    box-shadow: none;
    background-color: $neutral-color-white !important;
  }
}

.header-cell,
.body-cell {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;

  &.sticky {
    z-index: 1;
    position: sticky;
    left: 0;
    background: $neutral-color-white;
    box-shadow: 5px 0 5px rgba($borders-border-02, 0.7);
    border-right: 1px solid $borders-border-02;
  }
}

.header-cell {
  padding: 0 16px 8px 8px !important;
  color: $text-on-light-tertiary !important;
  background-color: $neutral-color-white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-bottom: transparent !important;
}

.body-cell {
  text-shadow: none;
  padding: 12px 16px 12px 8px !important;
  color: $text-on-light-primary !important;
  background-color: $neutral-color-white;
  transition: all 200ms ease;
  border-bottom: transparent !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;

  &.hidden {
    color: transparent !important;
    text-shadow: 0 0 4.5px $neutral-color-neutral-50;
    pointer-events: none;

    svg {
      filter: blur(4px);
    }
  }

  &.odd {
    background-color: $borders-border-01;
  }

  &.expanded {
    background-color: $tertiary-color-tertiary-05;
  }
}

.row {
  word-wrap: break-word;
  background-color: transparent;
  transition: background-color 300ms ease-out;

  &:hover {
    cursor: pointer;
  }

  &.hidden {
    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

.overlay-row {
  position: relative;

  .body-cell {
    position: relative;
    border-bottom: transparent !important;
  }
}

.overlay {
  left: 0;
  top: 6px;
  gap: 12px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title,
  .subtitle {
    font-weight: 500;
    max-width: 300px;
    text-align: center;
    color: $text-on-light-primary;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
  }

  .button {
    text-shadow: none;
    pointer-events: auto;
  }
}

.link {
  color: inherit;
  padding: 0;
  text-decoration: underline;

  .hidden & {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.address {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .country {
    gap: 4px;
    display: flex;
    align-items: center;

    .icon {
      height: 16px;
      width: 16px;

      path {
        fill: $icon-on-light-primary;
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.modal {
  padding: 32px 40px !important;
}