@import '../../styles';

.container {
  width: 260px;
  bottom: 16px;
  z-index: 100;
  position: sticky;
  margin-top: 16px;
  left: calc(50% - 504px);
  background: $neutral-color-white;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);

  .header {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    justify-content: space-between;
  }

  .expand-icon {
    margin-left: 4px;

    &:hover {
      cursor: pointer;

      path {
        fill: $neutral-color-neutral-50;
      }
    }
  }

  .title-container {
    display: flex;
    align-items: center;

    .filter-icon {
      margin-right: 4px;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-on-light-tertiary;
    }
  }

  .content {
    border-top: 1px solid $borders-border-03;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;

      .row-content {
        width: 216px;
        display: flex;
        align-items: center;

        .color {
          width: 10px;
          height: 10px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        .row-title {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          max-width: 120px;
          color: $text-on-light-tertiary;
        }

        .row-value {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-left: 4px;
          color: $text-on-light-primary;
        }
      }

      .cross-icon {
        margin-left: 4px;

        &.disabled {
          opacity: 0.7;
          pointer-events: none;
        }

        &:hover {
          cursor: pointer;

          path {
            fill: $neutral-color-neutral-50;
          }
        }
      }
    }
  }
}