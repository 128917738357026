@import '../../../styles';

.container {
  max-width: unset;

  @media (min-width: $md) {
    max-width: 480px;
  }
}

.heading {
  color: $text-on-light-primary;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 48px;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: transparent;
  gap: 24px;
  margin-top: 80px;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .details,
  .actions {
    display: flex;
    flex-direction: column;
  }

  .details {
    gap: 8px;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: $neutral-color-neutral-90;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $neutral-color-neutral-80;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    .spinner {
      color: $text-on-light-primary !important;
    }

    .button {
      flex-shrink: 0;
      white-space: nowrap;
      gap: 16px;
    }
  }
}