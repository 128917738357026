@import '../../../styles';

.heading {
  font-family: 'Figtree', sans-serif !important;
  color: $text-on-light-primary;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  max-width: 800px;
  margin: 58px auto 0;
  padding-top: 32px;

  @media (min-width: $lg) {
    font-size: 64px;
    line-height: 72px;
    margin: 0 auto;
  }
}

.description {
  margin: 32px auto 40px;
  color: $text-on-light-secondary;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  max-width: 1040px;
  white-space: pre-line;
}