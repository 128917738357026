@import '../../../styles';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  gap: 24px;
  backdrop-filter: blur(5px);
  background-color: $neutral-color-white;
  border: 1px solid $borders-border-02;
  margin-top: 24px;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .details,
  .actions {
    display: flex;
    flex-direction: column;
  }

  .details {
    gap: 8px;

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: $text-on-light-primary;
    }

    .note {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-on-light-secondary;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    width: 100%;

    @media (min-width: $md) {
      width: 260px;
      min-width: 260px;
      align-items: flex-end;
    }

    .button-container {
      width: 100%;
      position: relative;

      .button-tooltip {
        position: absolute;
        top: -15px;
        right: -15px;
        border-radius: 12px;
        padding: 2px 8px;
        background-color: $tertiary-color-tertiary-20;
        color: $neutral-color-neutral-90;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .button {
      position: relative;
      width: 100%;
      flex-shrink: 0;
      white-space: nowrap;
      gap: 16px;
    }
  }
}
