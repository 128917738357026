@import '../../styles';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    >* {
      width: 100%;
    }
  }

  .input-container {
    margin-bottom: 16px;
  }

  .placeholder {
    color: $neutral-color-neutral-30;
  }

  .label {
    align-self: flex-start;
    color: $neutral-color-neutral-90;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .submit {
    width: 100%;
    padding: 12px 24px;
    margin-top: 40px;

    @media (min-width: $md) {
      padding: 16px 32px;
    }
  }

  .error {
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    margin: 3px 14px 0 14px;
    color: $feedback-color-error;
  }
}

.checkbox-container {
  margin-bottom: 24px;

  .checkbox-content {
    display: flex;
    align-items: center;

    .checkbox-label {
      color: $neutral-color-neutral-90;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      a {
        text-decoration: underline;
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .checkbox-label-secondary {
      color: $neutral-color-neutral-60;
    }

    .checkbox {
      padding: 0 20px 0 0;
      color: $neutral-color-neutral-50;

      &:hover {
        background: transparent !important;
      }

      svg {
        height: 20px;
        width: 20px;
      }

      rect {
        clip-path: inset(0 0 0 0) !important;
        fill: $neutral-color-white !important;
        stroke: $neutral-color-neutral-50;
        rx: 0;
      }

      &:global(.Mui-checked) {
        svg {
          border: 1px solid $neutral-color-neutral-80;
          background-color: $neutral-color-neutral-80;
        }
      }
    }
  }

  .checkbox-error {
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    margin: 3px 0 0 0;
    color: $feedback-color-error;
  }
}

.menu {
  :global {
    .MuiPaper-root {
      margin: 0 auto;
      border-radius: 0 !important;
      background-color: $primary-color-primary-10;
      box-shadow: none;

      @media (min-width: $md) {
        width: 100%;
        max-width: 240px !important;
      }

      .MuiList-root {
        padding: 12px 0;
        background-color: $primary-color-primary-10;

        li {
          min-height: 0 !important;
          background-color: $primary-color-primary-10;
        }

        .MuiButtonBase-root {
          color: $text-on-light-secondary;
          padding: 8px 0 8px 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background-color: $tertiary-color-tertiary-10;
          }

          &.Mui-selected {
            background-color: $tertiary-color-tertiary-40;
          }
        }
      }
    }
  }
}