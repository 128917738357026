@import "../../styles";

.toast {
  --toastify-toast-width: auto;

  :global {
    .Toastify__toast-body {
      padding: 0 12px 0 0;
    }

    .Toastify__close-button {
      width: 24px;
      height: 24px;
      position: relative;
      -webkit-mask-image: url('../../assets/CloseIcon.svg');
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-image: url('../../assets/CloseIcon.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      align-self: center;
      flex-shrink: 0;
      opacity: 1;
      background-color: $primary-color-primary-50;

      &:hover {
        opacity: 0.7;
      }

      svg {
        display: none;
      }
    }

    .Toastify__toast {
      --toastify-toast-min-height: 56px;
      --toastify-toast-max-height: 800px;

      min-width: 300px;
      max-width: 500px;
      padding: 16px;
      border-radius: 0;
      background-color: $primary-color-primary-05;
      color: $neutral-color-neutral-90;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    .Toastify__toast--info {
      background-color: $feedback-color-info-light;
      color: $feedback-color-info;

      .Toastify__close-button {
        background-color: $feedback-color-info;
      }
    }

    .Toastify__toast--error {
      background-color: $feedback-color-error-light;
      color: $feedback-color-error;

      .Toastify__close-button {
        background-color: $feedback-color-error;
      }
    }

    .Toastify__toast--success {
      background-color: $feedback-color-success-light;
      color: $feedback-color-success;

      .Toastify__close-button {
        background-color: $feedback-color-success;
      }
    }

    .Toastify__toast--warning {
      background-color: $feedback-color-warning-light;
      color: $feedback-color-warning;

      .Toastify__close-button {
        background-color: $feedback-color-warning;
      }
    }
  }
}