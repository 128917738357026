@import '../../../styles';


@keyframes dissappear {
  0% {
    opacity: 1;
    max-height: 3000px;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.chat {
  position: relative;
  z-index: 0;
  height: 100%;
  flex: 1;
  will-change: max-height, height;
  overflow: hidden;
  mask-image: linear-gradient(to top,
      transparent,
      $background-background 3%,
      $background-background 90%,
      transparent);

  @media (min-width: $md) {
    mask-image: linear-gradient(to top,
        transparent,
        $background-background 5%,
        $background-background 90%,
        transparent);
  }

  &.hidden {
    animation: dissappear 500ms ease-out forwards;
    animation-play-state: running;
  }
}