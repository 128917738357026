@import '../../styles';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  position: relative;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    padding: 48px 40px;
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  .card {
    width: 320px;

    @media (min-width: $md) {
      width: 770px;
    }

    &.error {
      width: 320px;

      @media (min-width: $md) {
        width: 626px;
      }
    }
  }

  .title {
    text-align: center;
    margin: 16px 0;

    @media (min-width: $md) {
      margin: 24px 0;
    }
  }

  .subtitle {
    font-size: 16px;
    max-width: 224px;
    margin-bottom: 24px;

    @media (min-width: $md) {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  .loader {
    width: 60px !important;
    height: 60px !important;
  }
}