@import "../../../../styles";

.modal {
  padding-top: 20px !important;
}

.section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;

  .title {
    color: $text-on-light-primary;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .rows {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
      gap: 8px;
      display: flex;
      width: 100%;
      padding-bottom: 8px;
      border-bottom: 1px solid $borders-border-03;

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 160px;
        color: $text-on-light-tertiary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;

        @media (min-width: $md) {
          width: 220px;
        }
      }

      .value {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $text-on-light-primary;
      }
    }
  }
}

