@import '../../styles';

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 320px;
  padding: 40px 16px;
  border-radius: 0;
  background: $neutral-color-white;
  border: 1px solid $borders-border-02;

  @media (min-width: $md) {
    padding: 56px 80px;
    width: 650px;
  }
}
