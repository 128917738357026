@import "../../../../styles";

.card {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: $text-on-light-primary;
  }
}