@import '../../../styles';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }
}

.placeholder-state {
  padding: 36px 48px 71px;
  background-color: rgba($primary-color-primary-05, 0.85);
  border: 1px solid $primary-color-primary-30;
  animation: $appear-default-animation;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;

  .placeholder-state-title {
    margin-top: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
    color: $text-on-light-primary;

    @media (min-width: $md) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .placeholder-state-description {
    max-width: 640px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    color: $text-on-light-primary;
    text-align: center;

    @media (min-width: $md) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}