@import '../../styles';

.container {
  .button {
    margin-right: 0;
  }

  .icon  {
    path {
      fill: $primary-color-primary-50;
    }
  }
}
