@import '../../../styles';

.container {
  width: calc(100% - 4px);
  margin: 24px 2px 8px;

  .histogram {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1px;
    height: 14px;
    width: calc(100% - 6px);
    margin: 0 auto -17px;

    .histogram-item {
      flex: 1;
      background-color: $primary-color-primary-40;
      transition: all ease .3s;
    }

    .histogram-item-active {
      background-color: $tertiary-color-tertiary-50;
    }
  }

  .input {
    box-sizing: border-box;
    margin: 0;
    z-index: 100;
    width: 100%;
    height: 40px;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      box-sizing: border-box;
      background-color: $primary-color-primary-05;
      height: 4px;
      border: 1px solid $primary-color-primary-40;
    }

    &::-moz-range-track {
      box-sizing: border-box;
      background-color: $primary-color-primary-05;
      height: 4px;
      border: 1px solid $primary-color-primary-40;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
      border-radius: 0;
      background-color: $neutral-color-white;
      border: 1px solid $tertiary-color-tertiary-50;
      box-shadow: 0 0 0 2px rgba($tertiary-color-tertiary-50, 0.4);
      margin-top: -3px;
    }

    &::-moz-range-thumb {
      appearance: none;
      width: 8px;
      height: 8px;
      border-radius: 0;
      background-color: $neutral-color-white;
      border: 1px solid $tertiary-color-tertiary-50;
      box-shadow: 0 0 0 2px rgba($tertiary-color-tertiary-50, 0.4);
      margin-top: -3px;
    }
  }

  .scale {
    display: block;
    max-width: 100%;
    margin: -21px auto 0;
  }
}