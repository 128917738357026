@import '../../../styles';

.container {
  height: 100%;

  .switch {
    position: absolute;
    top: 15px;
    right: 80px;
    z-index: 10;
  }
}

.title {
  max-width: 650px;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-on-light-tertiary;
  margin-bottom: 4px;
  margin-right: 48px;

  @media (min-width: $md) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
  }
}

.subtitle,
.note,
.filters,
.content {
  &.hidden {
    filter: blur(3px);
    pointer-events: none;
  }
}

.subtitle {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-on-light-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 24px;
  max-width: 330px;

  @media (min-width: $md) {
    font-size: 14px;
    line-height: 20px;
  }
}

.note {
  display: flex;
  overflow: hidden;
  color: $neutral-color-neutral-40;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    flex-shrink: 0;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 10px;

  @media (min-width: $xl) {
    flex-direction: row;
    gap: 24px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.footer {
  &.hidden {
    filter: blur(3px);
    pointer-events: none;
  }

  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;

  @media (min-width: $lg) {
    gap: 24px;
  }

  .separator {
    width: 1px;
    height: 20px;
    display: inline-block;
    background: $borders-border-06;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .color {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 4px;
    border-radius: 0;
  }

  .label {
    color: $text-on-light-tertiary;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-right: 8px;

    @media (min-width: $lg) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .value {
    color: $text-on-light-secondary;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;

    &.hidden {
      filter: blur(3px);
    }

    @media (min-width: $lg) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.icon {
  path {
    stroke: $neutral-color-neutral-80;
  }
}