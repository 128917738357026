@import '../../../../styles';

.modal {
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
}

.zoomed-image {
  display: block;
  height: auto;
  max-width: 320px;
  border-radius: 8px;

  @media (min-width: $md) {
    max-width: 600px;
  }
}