@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation {
  animation: appear 500ms ease-out 500ms forwards;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;

  &.hidden {
    display: none;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}