@import '../../styles';

.root {
  z-index: 1299 !important;
  right: unset !important;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  transition: all 300ms ease-out;

  .close-container {
    position: absolute;
    top: 16px;
    right: -12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $neutral-color-white;
    border: 1px solid $surfaces-light-surface-02;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .logo-container {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  .logo {
    display: flex;

    svg {
      height: 52px;
      width: 102px;
    }
  }

  .close {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    transition: all 300ms ease-out;
  }

  &.collapsed {
    padding: 16px 0;

    @media (min-width: $lg) {
      padding: 16px 24px;
    }

    .title-container {
      width: 0;
      visibility: hidden;
    }

    .close {
      transform: rotate(0deg);
      transition: all 300ms ease-out;
    }

    .close-container {
      visibility: hidden;

      @media (min-width: $lg) {
        visibility: visible;
      }
    }
  }

  .content {
    overflow: hidden;
    display: flex;
    gap: 24px;
    margin-top: 100px;
    flex-direction: column;

    @media (min-width: $md) {
      margin-top: 200px;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: $text-on-light-tertiary;
      white-space: break-spaces;
      min-width: 150px;
    }

    .icon-container {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }

    .plus {
      width: 16px;
      height: 16px;
      display: flex;
      flex-shrink: 0;
      visibility: hidden;
      align-items: center;
      justify-content: center;
      color: $neutral-color-white;
      background: $surfaces-colored-surface-03;

      &:hover {
        cursor: pointer;
      }
    }

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      background: transparent;

      path {
        fill: $neutral-color-neutral-60;
      }
    }

    &:hover,
    &.active {
      .icon {
        path {
          fill: $tertiary-color-tertiary-70;
        }
      }

      .plus {
        visibility: visible;
      }
    }
  }

  .footer {
    overflow: hidden;
    margin-top: auto;

    .menu-item {
      color: $text-on-light-primary;
    }
  }
}

.paper {
  width: 100%;
  max-width: 256px;
  overflow: visible !important;
  border-radius: 0 !important;
  box-shadow: $box-shadow-default !important;
  background-color: $primary-color-primary-05 !important;
  visibility: visible !important;
  transition: all 300ms ease-out !important;
  pointer-events: auto !important;

  @media (min-width: $md) {
    border-radius: 0 !important;
  }

  &.collapsed {
    max-width: 0;
    transition: all 300ms ease-out !important;
    transform: translateX(0) !important;
    visibility: visible !important;

    @media (min-width: $lg) {
      max-width: 80px;
    }
  }
}