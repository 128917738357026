@import '../../../styles/index';

.container {
  gap: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1040px;
  margin: 72px auto 64px;

  .heading {
    font-family: 'Figtree', sans-serif !important;
    color: $text-on-light-primary;
    text-align: center;
    font-size: 57px;
    font-weight: 400;
    line-height: 64px;
    white-space: pre-line;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
  }

  .button {
    margin-top: 16px;
    background-color: white;
    padding: 9px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    box-sizing: border-box;

    @media (min-width: $md) {
      margin-top: 32px;
    }

    &:hover {
      background-color: white;
    }
  }

  .arrow-icon {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    transition: all 300ms ease-out;
    fill: $neutral-color-neutral-90;

    &.expanded {
      transform: rotate(180deg);
      transition: all 300ms ease-out;
    }
  }

  .collapse {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
}