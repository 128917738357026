@import '../../../styles';

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.recharge-dashboards {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: $neutral-color-neutral-80;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.container {
  overflow: hidden;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-container {
    width: 100%;
    display: flex;
    visibility: visible;
    flex-direction: column;

    &.collapsed {
      width: 0;
      visibility: hidden;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    color: $text-on-light-secondary;
  }

  .link {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    white-space: nowrap;
    color: $tertiary-color-tertiary-60;
  }

  .badge {
    width: 34px;
    height: 40px;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    align-items: center;
    justify-content: center;
    color: $text-on-light-primary;
    background: $surfaces-light-surface-02;

    &.counter-animating {
      animation: pulse 700ms ease forwards;
    }
  }
}