@import '../../styles';

.modal {
  width: 100%;
  max-width: 345px;
  position: relative;
  padding: 56px 16px !important;
  border-radius: 0 !important;
  background: $neutral-color-white !important;

  @media (min-width: $md) {
    padding: 56px 40px !important;
    max-width: 650px;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s ease-out;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    .close-icon {
      path {
        fill: $neutral-color-black;
      }
    }
  }

  .content {
    gap: 16px;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    @media (min-width: $md) {
      gap: 24px;
    }
  }

  .main-content {
    overflow: auto;
  }

  .title {
    font-family: "Rufina", serif !important;
    padding: 0 !important;
    color: $text-on-light-primary;
    font-size: 32px !important;
    line-height: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

:global {
  .MuiDialog-root .MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgba($primary-color-primary-90, 0.6);
  }
}