@import '../../styles';

.card {
  width: calc(100% - 48px);
  max-width: 650px;
  margin: 0 24px;
  padding: 46px 40px;
}

.title,
.subtitle {
  text-align: center;
  margin-top: 16px;

  @media (min-width: $md) {
    margin-top: 24px;
  }
}

.title {
  font-weight: 400;

  @media (min-width: $md) {
    font-weight: 500;
  }
}

.subtitle {
  color: $text-on-light-secondary;
  font-size: 16px;
  line-height: 24px;
}

.actions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (min-width: $lg) {
    flex-direction: row;
    gap: 24px;
  }
}

.progress {
  circle {
    stroke: $tertiary-color-tertiary-20;
  }
}