@import '../../../styles';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title,
  .subtitle {
    white-space: pre-line;
  }

  .icon {
    margin-bottom: 24px;
  }

  .title {
    justify-content: center !important;
    text-align: center;
    margin-bottom: 24px;
    font-weight: 500;
    color: $neutral-color-neutral-90;
  }

  .subtitle {
    text-align: center;
    font-size: 18px;
    color: $text-on-light-secondary;
    max-width: 480px;
  }

  .actions {
    display: flex;
    margin-top: 40px;
    justify-content: center;
  }
}