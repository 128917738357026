@import '../../../../styles';

.zoomable-sunburst {
  overflow: visible;

  .text {
    font-size: 12px;
    fill: $neutral-color-neutral-40;
  }

  .tick {
    line {
      stroke: $neutral-color-neutral-40;
    }

    text {
      fill: $neutral-color-neutral-40;
    }
  }

  .domain {
    stroke: $neutral-color-neutral-40;
  }

  rect {
    cursor: pointer;
    clip-path: inset(0 0 2px 0);
    color: $neutral-color-neutral-20;
  }
}