@import '../../styles';
@import 'node_modules/spinthatshit/src/loaders';

.overlay {
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1100;
  color: $text-on-light-secondary;
  background-color: rgba($neutral-color-white, 0.5) !important;

  .overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 470px;
    padding: 0 16px;
    margin: 0 auto;
    text-align: center;
    gap: 16px;
    line-height: 1.5;
    white-space: pre-line;
  }

  .text {
    font-size: 18px;
    margin-top: 100px;
  }

  .spinner {
    @include loader04($color: $primary-color-primary-80,
      $size: 60px,
      $duration: 1.2s,
      $border-size: 4px);

    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
}