@import '../../../styles';

.container {
  padding: 24px;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid $borders-border-02;
  background: $surfaces-light-surface-01;

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: $text-on-light-primary;
  }

  .image {
    height: auto;
    width: 100%;
    margin: 12px 0;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-on-light-secondary;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

}