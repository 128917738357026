@import '../../styles';

.container {
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  .heading {
    font-family: 'Figtree', sans-serif !important;
    color: $neutral-color-white;
    text-align: center;
    font-size: 45px;
    font-weight: 400;
    line-height: 56px;

    @media (min-width: $md) {
      font-size: 72px;
      line-height: 80px;
    }
  }

  .switch-container {
    display: flex;
    margin-top: 24px;
    justify-content: center;
  }

  .badge {
    border-radius: 12px;
    padding: 2px 8px;
    background-color: $tertiary-color-tertiary-20;
    color: $neutral-color-neutral-90;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .plans {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .loader {
    width: 60px !important;
    height: 60px !important;
  }
}