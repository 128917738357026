@import '../../../../styles/index';

.container {
  .title,
  .subtitle {
    text-align: center;
    white-space: pre-line;
  }

  .title {
    justify-content: center !important;
  }

  .subtitle {
    color: $neutral-color-neutral-80;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 40px;

    @media (min-width: $md) {
      flex-direction: row;
      gap: 24px;
    }

    .button {
      width: 100%;

      @media (min-width: $md) {
        flex-direction: row;
        width: 50%;
      }
    }
  }
}