@import '../../../styles';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: auto;
  background-color: $neutral-color-white;
  box-shadow: $box-shadow-default;
  flex-shrink: 0;
  max-width: 455px;
  height: 100dvh;

  @media (min-width: $md) {
    margin-top: -$header-height;
  }

  .tabs {
    display: flex;
    overflow: hidden;
    flex-shrink: 0;
    max-height: 56px;

    .tab {
      flex-grow: 1;
      position: relative;
      padding: 20px 16px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      background-color: $primary-color-primary-05;
      cursor: pointer;
      color: transparent;
      transition: background-color .2s ease, box-shadow .2s ease;

      &:before {
        content: attr(data-tab);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $neutral-color-neutral-90;
        font-weight: 400;
        transition: font-weight .2s ease;
      }

      &.active {
        background-color: $neutral-color-white;
        box-shadow: $box-shadow-default;

        &:before {
          color: $neutral-color-neutral-90;
          font-weight: 700;
        }
      }
    }
  }

  .content {
    padding: 24px;
    flex-grow: 1;
    overflow: auto;
  }

}