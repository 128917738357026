@import '../../../styles';

.container {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 86px;
  }

  @media (min-width: $lg) {
    gap: 107px;
  }

  .item {
    width: 100%;
    padding-bottom: 48px;

    @media (min-width: $md) {
      padding-bottom: 64px;
    }
  }

  .date {
    display: block;
    position: sticky;
    top: 15px;
    flex-shrink: 0;
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: $text-on-light-tertiary;
    margin-bottom: 16px;

    @media (min-width: $md) {
      margin: -8px 0 16px auto;
      text-align: right;
    }

    &:after {
      content: '';
      display: none;
      width: 6px;
      height: 6px;
      margin: 4px -3.5px 0 12px;
      background-color: $primary-color-primary-30;
      vertical-align: top;
      border-radius: 100px;

      @media (min-width: $md) {
        display: inline-block;
      }
    }
  }

  .timeline {
    flex: 1 0;
    position: relative;

    @media (min-width: $md) {
      min-width: 200px;
      border-right: 1px solid $primary-color-primary-30;
    }

    &.last {
      border-color: transparent;

      .date {
        position: static;
      }
    }
  }

  .title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-top: -6px;
    color: $text-on-light-primary;
  }

  .content {
    display: block;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    overflow: hidden;
    color: $text-on-light-primary;

    b {
      font-weight: 700;
    }
  }

  .image {
    overflow: hidden;
    margin-top: 16px;
    border: 1px solid $border-color-secondary-30;

    @media (min-width: $md) {
      margin-top: 32px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      aspect-ratio: 1201 / 742;
    }
  }
}