@import '../../../../styles';

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  font-family: 'Figtree', sans-serif !important;
  justify-content: center !important;
  text-align: center;
}

.subtitle {
  text-align: center;
  font-size: 18px;
  color: $text-on-light-secondary;
  max-width: 480px;
}

.additional-info {
  text-align: left;
  font-size: 14px;
  color: $text-on-light-secondary;
}

.input-container {
  margin: 20px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 24px;
  }

  .button {
    &.cancel {
      width: 100%;

      @media (min-width: $md) {
        width: 30%;
      }
    }

    &.submit {
      width: 100%;

      @media (min-width: $md) {
        width: 70%;
      }
    }

    @media (min-width: $md) {
      flex-direction: row;
    }
  }
}