@import '../../../styles';

$container-top-padding: 60px;
$container-bottom-padding: 32px;
$filter-indent: 60px;

.container-wrapper {
  position: relative;
  padding: 0 32px $container-bottom-padding;
  max-width: 1040px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  overflow-x: scroll;
  height: 100%;
  margin: 66px auto 0;

  @media (min-width: $md) {
    gap: 32px;
    box-sizing: border-box;
    height: calc(100vh - $header-height - $container-bottom-padding - $filter-indent);
    height: calc(100dvh - $header-height - $container-bottom-padding - $filter-indent);
  }

  @media (min-width: $lg) {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100px;
    overflow-x: visible;
    height: calc(100vh - $header-height);
    height: calc(100dvh - $header-height);
  }
}

.filter {
  left: 32px;
  top: -64px;
  bottom: unset !important;
  position: absolute !important;
}

.container {
  gap: 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $lg) {
    gap: 32px;
    height: calc(100vh - $header-height - $container-bottom-padding);
    height: calc(100dvh - $header-height - $container-bottom-padding);
    box-sizing: content-box;
    margin: -60px auto 0;
    padding-top: 60px;
    overflow: hidden;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    flex-direction: column;

    @media (min-width: $lg) {
      gap: 32px;
      flex-direction: row;
    }
  }

  .filter {
    position: absolute;
    top: 0;
    left: 32px;
    bottom: unset;
    margin: 0;
  }
}