@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .filter-container {
    border-bottom: none;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  width: calc(100% - 140px);
  color: $text-on-light-tertiary;
}

.zoomable-sunburst-chart-container {
  width: 100%;
  margin-top: 24px;
  overflow: visible;
}

.summary {
  margin-top: 24px;
}