@import '../../../../styles';

.modal {
  max-width: 320px !important;
  padding: 56px 16px !important;

  @media (min-width: $md) {
    padding: 56px 80px !important;
    max-width: 704px !important;
  }

  .title,
  .subtitle {
    white-space: pre-line;
  }

  .title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: $text-on-light-primary;
    margin-bottom: 24px;
    font-family: 'Figtree', sans-serif;

    @media (min-width: $md) {
      font-size: 45px;
      font-weight: 400;
      line-height: 56px;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 28px;
    color: $text-on-light-secondary;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 40px;

    @media (min-width: $md) {
      flex-direction: row;
      gap: 24px;
    }

    .button {
      font-size: 16px;

      @media (min-width: $md) {
        flex-direction: row;
      }
    }
  }
}

