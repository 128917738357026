@import '../../styles';

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  font-family: 'Figtree', sans-serif !important;
  justify-content: center !important;
  text-align: center;
}

.subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: $text-on-light-secondary;

  &.indented {
    margin-top: 24px;
  }
}

.main-content {
  margin: 40px 0;

  .input-container {
    margin-top: 24px;

    .label {
      color: $text-on-light-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .container {
      width: 100%;
      padding: 12px;
      margin-top: 4px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      background-color: $light-grey-01;
      color: $text-on-light-primary !important;
      border-color: transparent !important;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 24px;
  }

  .cancel {
    width: 100%;

    @media (min-width: $md) {
      width: 30%;
    }
  }

  .submit {
    width: 100%;

    @media (min-width: $md) {
      width: 70%;
    }
  }
}