@import '../../styles';

@keyframes slideIn {
  100% {
    transform: translateX(0%);
  }
}

.container {
  animation: slideIn .3s ease forwards;
  display: flex;
  gap: 16px;
  width: 100%;
  background-color: $neutral-color-white;
  box-shadow: $box-shadow-default;
  padding: 16px;
  cursor: pointer;
  transform: translateX(-100%);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    background-color: $primary-color-primary-05;
    border: 1px solid $primary-color-primary-30;
  }

  .content {
    overflow: hidden;
    width: 100%;

    .heading {
      position: relative;
      padding-right: 24px;

      .title {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: $neutral-color-neutral-80;
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }

    .body {
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: $neutral-color-neutral-90;
    }
  }
}