@import '../../styles';

.hidden {
  display: none;
}

.dashboard-wrapper {
  display: block;
  margin-bottom: 80px;
}

@media print {
  :global {

    ::selection {
      background: none;
      color: inherit;
    }

    html,
    body {
      display: none !important;
      visibility: hidden !important;
    }
  }
}

.unlock-button-wrapper {
  width: 100%;
  background-color: rgba($neutral-color-white, 0.95);

  .unlock-button {
    max-width: 1040px;
    padding: 0 16px;
    margin: 0 auto;

    @media (min-width: $md) {
      padding: 0 32px;
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 24px;
      padding: 2px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 9px;
      color: $text-on-light-primary;
      background-color: $surfaces-light-surface-03;
    }
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  position: relative;
  animation: $appear-default-animation;
  margin: 0 auto;
  max-width: 1040px;
  padding: 0 16px 20px;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  @media (min-width: $xl) {
    padding: 0;
  }

  * {
    user-select: none !important;
  }

  &.small-header {
    min-height: calc(100vh - $external-header-height);
    min-height: calc(100dvh - $external-header-height);

    @media (min-width: $md) {
      min-height: calc(100vh - $external-header-height);
      min-height: calc(100dvh - $external-header-height);
    }
  }
}

.grid-container {
  background: transparent;
}

.icon {
  width: 28px !important;
  height: 28px !important;

  path {
    fill: $neutral-color-white;
  }
}

.no-padding {
  padding: 0;
}

.card {
  background-color: $neutral-color-white;
}

.dashboard-history {
  grid-area: dashboard-history;
  box-shadow: $box-shadow-default;
}

.hierarchy-industries-card,
.map-dots-card {
  padding: 0;
}

.treemap {
  grid-area: treemap;
  box-shadow: $box-shadow-default;
}

.hierarchy-industries {
  grid-area: hierarchy-industries;
  box-shadow: $box-shadow-default;
}

.big-number-manufacturers {
  grid-area: big-number-manufacturers;
  box-shadow: $box-shadow-default;
}

.big-number-machines {
  grid-area: big-number-machines;
  box-shadow: $box-shadow-default;
}

.chatbot-answer {
  grid-area: chatbot-answer;
  background-color: $primary-color-primary-05;
  border: 0.5px solid $primary-color-primary-30;
}

.map-dots {
  grid-area: map-dots;
  box-shadow: $box-shadow-default;
}

.top-models {
  grid-area: top-models;
  box-shadow: $box-shadow-default;
}

.pie-chart-technologies {
  grid-area: pie-chart-technologies;
  box-shadow: $box-shadow-default;
}

.pie-chart-materials {
  grid-area: pie-chart-materials;
  box-shadow: $box-shadow-default;
}

.hierarchy-products {
  grid-area: hierarchy-products;
  box-shadow: $box-shadow-default;
}

.crm-booster {
  grid-area: crm-booster;
  background-color: $primary-color-primary-05;
  border: 0.5px solid $primary-color-primary-30;
}

.manufacturer-profiles {
  grid-area: manufacturer-profiles;
  box-shadow: $box-shadow-default;
}

.report-ad {
  grid-area: report-ad;
  background-color: $primary-color-primary-05;
  border: 0.5px solid $primary-color-primary-30;
}

.auto-height {
  height: auto !important;
}

.main-container {
  width: 100%;
  display: grid;
  grid-template-areas:
    'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history'
    'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers'
    'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines'
    'hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
    'crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster'
    'report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad'
    'chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer'
    'map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots'
    'pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies'
    'pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials'
    'pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description pie-chart-description'
    'top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models'
    'manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles'
    'hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products';
  gap: 18px;
  grid-auto-columns: minmax(0, 1fr);

  @media (min-width: $md) {
    gap: 18px;
    grid-template-areas:
      'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'crm-booster crm-booster crm-booster crm-booster crm-booster report-ad report-ad report-ad report-ad report-ad report-ad report-ad'
      'chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer'
      'map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots'
      'pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials'
      'top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models'
      'manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles'
      'hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products';
  }

  &.has-treemap {
    grid-template-areas:
      'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history'
      'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers'
      'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines'
      'hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
      'crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster crm-booster'
      'report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad report-ad'
      'chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer'
      'map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots'
      'pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies'
      'pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials'
      'top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models'
      'treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap'
      'manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles'
      'hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products';

    @media (min-width: $md) {
      grid-template-areas:
        'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'dashboard-history dashboard-history dashboard-history dashboard-history dashboard-history hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers big-number-manufacturers hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'big-number-machines big-number-machines big-number-machines big-number-machines big-number-machines hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries hierarchy-industries'
        'crm-booster crm-booster crm-booster crm-booster crm-booster report-ad report-ad report-ad report-ad report-ad report-ad report-ad'
        'chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer chatbot-answer'
        'map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots map-dots'
        'pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-technologies pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials pie-chart-materials'
        'top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models top-models'
        'treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap treemap'
        'manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles manufacturer-profiles'
        'hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products hierarchy-products';
    }
  }
}

.widget-card {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.empty-state {
  height: calc(100dvh - 306px);

  @media (min-width: $md) {
    height: calc(100dvh - 256px);
  }
}