svg {
  overflow: visible;
}

.rectangle-label {
  font-size: 22px;

  @media (min-width: 672px) {
    font-size: 12px;
  }
}