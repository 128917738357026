@import '../../../styles';

.heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 40px;
  margin-bottom: 24px;
  color: $neutral-color-neutral-80;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media (min-width: $md) {
    flex-direction: row;
  }

  .avatar {
    width: 80px;
    height: 80px;
    font-size: 24px;
    object-fit: cover;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .indicator {
      color: $neutral-color-white;
    }
  }

  .input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  }
}
