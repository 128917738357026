@import '../../styles';

.badge {
  display: inline-block;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-on-dark-primary;
  padding: 2px 8px;

  &.default {
    color: $text-on-dark-primary;
    background-color: $surfaces-dark-surface-03;
  }

  &.success {
    color: $text-on-light-primary;
    background-color: $secondary-color-secondary-40;
  }

  &.warning {
    color: $text-on-light-primary;
    background-color: $surfaces-colored-surface-04;
  }

  &.error {
    color: $text-on-dark-primary;
    background-color: $feedback-color-error;
  }
}
