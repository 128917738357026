@import '../../styles';

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px 12px 24px;
  background-color: $neutral-color-white;
  border: 1px solid $primary-color-primary-30;
  transition: border-color 200ms ease-out;

  &:hover {
    border-color: $tertiary-color-tertiary-10;
  }

  &.focused {
    border-color: $tertiary-color-tertiary-10;
  }

  .input {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 16px 0;
    border: none;
    background-color: $neutral-color-white;
    color: $neutral-color-neutral-90;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $neutral-color-neutral-40;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  .submit {
    flex-grow: 0;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}