@import '../../../styles';

.container {
  .input-container {
    margin-bottom: 24px !important;
  }

  .label {
    align-self: flex-start;
    color: $neutral-color-neutral-80;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .submit {
    width: 100%;
  }

  .button-container {
    display: inline-block;
    width: 100%;
    margin-top: 48px;

    @media (min-width: $md) {
      width: auto;
    }
  }
}

.menu {
  :global {
    .MuiPaper-root {
      margin: 0 auto;
      border-radius: 0;
      background-color: $primary-color-primary-80;
      box-shadow: none;

      @media (min-width: $md) {
        width: 100%;
        max-width: 240px !important;
      }

      .MuiList-root {
        padding: 12px 0;
        background-color: $primary-color-primary-80;

        li {
          min-height: 0 !important;
          background-color: $primary-color-primary-80;
        }

        .MuiButtonBase-root {
          color: $neutral-color-neutral-05;
          padding: 8px 0 8px 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background-color: $primary-color-primary-60;
          }

          &.Mui-selected {
            background-color: $primary-color-primary-70;
          }
        }
      }
    }
  }
}