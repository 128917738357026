@import '../../styles';

.main-link {
  color: $text-on-light-primary;
  padding: 0;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: $md) {
    margin-left: 16px;
  }
}

.forgot-password {
  align-self: flex-end;
}

.footer-subtitle {
  display: flex;
  flex-direction: column;
  color: $text-on-light-tertiary;
  font-size: 14px;
  font-weight: 400;

  @media (min-width: $md) {
    display: block;
    margin-bottom: 24px;
  }
}

.title {
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}

.subtitle {
  font-size: 16px;
  margin-bottom: 24px;

  @media (min-width: $md) {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
