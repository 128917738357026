@import '../../../styles';

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 32px 1fr;
  overflow: hidden;
  max-height: 1000px;
}

.button-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.title {
  z-index: 1;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  margin-right: 48px;
  color: $text-on-light-tertiary;

  @media (min-width: $md) {
    font-size: 24px;
    line-height: 32px;
  }
}

.chat {
  display: block;
  overflow: auto;
  mask-image: linear-gradient(to top,
      transparent,
      rgba(255, 255, 255, 0.95) 10%,
      rgba(255, 255, 255, 0.95) 75%,
      transparent);
}

.empty-history {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-on-light-tertiary;
}