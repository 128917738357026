@import '../../../../styles/index';

.card {
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 32px;
  border-radius: 0;
  flex-direction: column;
  color: $text-on-light-primary;
  font-family: 'Figtree', sans-serif;
  background-color: $neutral-color-white;
  border: 1px solid $borders-border-02;
  box-shadow: $box-shadow-default;

  .title-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    line-height: 28px;
    color: $text-on-light-primary;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
    color: $text-on-light-secondary;
    align-self: flex-start;

    @media (min-width: $md) {
      font-size: 16px;
      line-height: 24px;
    }

    a {
      color: $surfaces-colored-surface-03;
      text-decoration: underline;
    }
  }

  .actions {
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: flex-start;
    }

    .button {
      padding: 12px 24px;
      font-size: 16px;
      line-height: 24px;
      flex-shrink: 0;
    }
  }

  .icon {
    path {
      fill: $surfaces-colored-surface-03;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}