@import '../../styles';

.modal-wrapper {
  :global(.MuiPaper-root) {
    max-width: 800px !important;
    width: 100%;
  }

  .title {
    display: block !important;
    text-align: left;
    font-size: 24px !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    column-gap: 36px;
    row-gap: 16px;

    @media (min-width: $md) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .shortcut {
    width: 100%;
    display: flex;
    align-items: center;
    color: $text-on-light-secondary;
    justify-content: space-between;

    @media (min-width: $md) {
      width: calc(50% - 18px);
    }
  }

  .combination {
    display: flex;
    gap: 6px;

    .item {
      font-size: 14px;
      min-width: 32px;
      min-height: 32px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      color: $neutral-color-white;
      background-color: $primary-color-primary-70;
    }
  }
}
