@import "../../../styles";

.container {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  border-radius: 24px;
  justify-content: space-between;

  @media (min-width: $lg) {
    padding: 24px;
  }
}

.switch {
  position: absolute;
  top: 15px;
  right: 80px;
  z-index: 10;
}

.stepper-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .stepper {
    padding-bottom: 0;
    background: transparent !important;
  }

  .dot {
    height: 3px;
    width: 24px;
    margin: 0 3px;
    border-radius: 2px;
    background-color: $surfaces-light-surface-02;

    @media (min-width: $md) {
      width: 40px;
      margin: 0 5px;
    }

    @media (max-width: 500px) {
      width: 18px;
    }
  }

  .active-dot {
    background-color: $surfaces-light-surface-03;
  }

  .button {
    width: 32px;
    height: 32px;
    border-radius: 0 !important;
    color: $icon-on-light-primary;
    border: 1px solid $borders-border-03;
    background-color: $surfaces-light-surface-01;

    &:hover,
    &:active {
      background-color: $surfaces-light-surface-02;
    }

    &:disabled {
      opacity: 0.45;
      color: $icon-on-light-primary;
      background-color: $surfaces-light-surface-01;
    }

    &:first-child {
      margin-right: 8px;

      @media (min-width: $md) {
        margin-right: 12px;
      }
    }

    @media (min-width: $md) {
      width: 40px;
      height: 40px;
    }
  }
}

.icon {
  path {
    fill: $neutral-color-neutral-80;
  }
}

.card-wrapper {
  height: 100%;
  overflow-y: visible;
}

.background {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  background-image: url("../../../assets/map.png");

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }
}