@import '../../styles';

.title {
  font-family: 'Figtree', sans-serif !important;
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}

.subtitle {
  font-size: 16px;
  max-width: 224px;
  margin-bottom: 24px;

  @media (min-width: $md) {
    font-size: 18px;
    max-width: 100%;
    margin-bottom: 40px;
  }
}