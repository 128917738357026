@import '../../../styles/index';

.container {
  gap: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $lg) {
    gap: 0;
    flex-direction: row;
  }

  .actions {
    display: flex;
    justify-content: center;
  }

  .title,
  .subtitle {
    white-space: pre-line;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    color: $neutral-color-neutral-90;
  }

  .subtitle {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $neutral-color-neutral-80;
  }

  .cta {
    margin: 0 auto;
    text-align: center;
    width: 218px !important;

    @media (min-width: $md) {
      width: 100% !important;
    }

    @media (min-width: $lg) {
      width: 250px !important;
    }
  }
}