@import '../../../styles';

.container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background-color: $primary-color-primary-05;
  border: 1px solid $primary-color-primary-30;
  border-bottom: none;

  @media (min-width: $md) {
    padding: 12px 24px;
  }

  .title {
    font-weight: 600;
    color: $neutral-color-neutral-90;
  }

  .description {
    color: $neutral-color-neutral-80;
  }

  .cta {
    font-weight: 600;
    color: $tertiary-color-tertiary-70;
    text-decoration: none;
    transition: opacity .2s ease;

    &:hover {
      opacity: .7;
    }
  }
}