@import '../../../styles';
@import 'node_modules/spinthatshit/src/loaders';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 0.5px solid $borders-border-03;

  .filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .filter {
      padding: 12px;
      flex-grow: 1;
      min-width: 0;
      box-shadow: $box-shadow-default;
      border: 1px solid $neutral-color-white;
    }
  }

  .note {
    display: flex;
    align-items: center;
    color: $neutral-color-neutral-50;
    font-size: 10px;

    svg {
      height: 16px;

      path {
        stroke: $neutral-color-neutral-50;
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  color: $neutral-color-neutral-40;

  &.has-value {
    color: $text-on-light-primary;
  }
}

.button {
  flex-shrink: 0;
}