@import '../../styles';

.container {
  animation: $appear-default-animation;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    margin-top: 32px;
    min-height: calc(100vh - $header-height - 32px);
    min-height: calc(100dvh - $header-height - 32px);
  }

  .title {
    margin-bottom: 16px;
  }

  .content {
    position: relative;
    height: 100%;
    padding: 40px 16px 40px;
    max-width: 1040px;
    margin: 0 auto;

    @media (min-width: $md) {
      padding: 0 16px 80px;
    }

    @media (min-width: $xl) {
      padding: 0 0 80px 0;
    }

    @media (min-width: $lg) and (max-width: $xl) {
      max-width: 890px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    gap: 8px;

    @media (min-width: $md) {
      margin-bottom: 40px;
      padding-top: 0;
    }
  }

  .tab {
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $text-on-light-secondary;
    background-color: transparent;
    text-decoration: none;
    border-color: transparent;

    &:hover {
      background-color: rgba($tertiary-color-tertiary-10, 0.3);
    }

    &.active {
      background-color: $tertiary-color-tertiary-05;
      border: 1px solid $tertiary-color-tertiary-20;
    }
  }
}