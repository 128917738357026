@import '../../styles';

$filter-indent: 56px;

.container {
  position: relative;
  display: flex;
  animation: $appear-default-animation;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }
}

.filter {
  width: 100%;
  top: 0;
  bottom: unset;
  z-index: 100;
  position: absolute;
  left: 0;
  height: 56px;
  padding: 12px 4px;
  background: $primary-color-primary-05;
  border-top: 1px solid $borders-border-02;
  border-bottom: 1px solid $borders-border-02;
  box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);

  .arrow-icon {
    width: 20px !important;
    height: 20px !important;
    transform: rotate(0deg);
    transition: all 300ms ease-out;
    fill: $icon-on-light-tertiary;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &.expanded {
      transform: rotate(180deg);
      transition: all 300ms ease-out;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    align-items: center;

    .filter-icon {
      margin-right: 4px;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-on-light-tertiary;
    }
  }

  .collapsable-container {
    margin-top: 15px;
    max-width: unset;
    margin-left: -3px;
    margin-right: -3px;
    height: calc(100dvh - $header-height-mobile - $filter-indent);

    @media (min-width: $md) {
      height: calc(100dvh - $header-height - $filter-indent);
    }
  }
}