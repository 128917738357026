@import '../../../styles';

.heading-wrapper {
  max-width: 1040px;
  position: sticky;
  padding: 15px 16px 24px;
  top: 0;
  z-index: 1000;
  margin: 0 auto;
  background: linear-gradient(180deg,
      rgba($neutral-color-white, 0.95) 0%,
      rgba($neutral-color-white, 0.8) 60%,
      rgba($neutral-color-white, 0) 100%);

  @media (min-width: $md) {
    padding: 15px 16px 32px;
  }

  .heading {
    max-width: 1040px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: center;
    }

    .title {
      color: $text-on-light-primary;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;

      @media (min-width: $md) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .date {
      gap: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      @media (min-width: $md) {
        flex-direction: column;
      }

      .row {
        display: inline-block;
        color: $text-on-light-tertiary;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
}