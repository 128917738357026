@import '../../../../styles';

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $text-on-light-tertiary;
  width: calc(100% - 48px);
  padding: 16px 24px 0;
}

.filter {
  padding: 0 24px 16px;
}