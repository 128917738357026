@import '../../styles';

.loader-container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 30px !important;
    height: 30px !important;
    color: $primary-color-primary-60 !important;
  }
}
