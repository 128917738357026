@import '../../../styles';

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip {
  margin-top: 14px !important;
  margin-right: 14px !important;

  @media (min-width: $md) {
    margin-right: 44px !important;
  }
}

.message-wrapper {
  &.companies-list-limited {
    transition: border .3s ease;
    border: 1px solid $primary-color-primary-30;
  }

  &:first-of-type {
    margin-bottom: 16px;
    animation: appear 300ms ease-out forwards;

    @media (min-width: $md) {
      margin-bottom: 24px;
    }
  }
}

.message {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
  background-color: $primary-color-primary-05;

  @media (min-width: $md) {
    padding: 24px;
  }

  .suggestions {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 12px;
    margin-top: 24px;

    @media (min-width: $lg) {
      margin-top: 32px;
      gap: 24px 32px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .suggestion {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      background-color: $neutral-color-white;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $neutral-color-neutral-90;
      transition: border-color 200ms ease-out;
      border: 1px solid $neutral-color-white;
      cursor: pointer;
      box-shadow: $box-shadow-default;

      @media (min-width: $md) {
        font-size: 16px;
        line-height: 24px;
      }

      &:hover {
        border-color: $tertiary-color-tertiary-30;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }

  .interrupted-note {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    margin-top: 16px;
    color: $neutral-color-neutral-30;
  }

  .button {
    flex-shrink: 0;
    align-self: center;
    margin-top: 16px;
    margin-right: 36px;

    @media (min-width: $md) {
      margin-top: 24px;
      margin-right: 44px;
    }
  }
}