@import '../../../styles';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  text-align: center;
  margin: 16px 0;

  @media (min-width: $md) {
    margin: 24px 0;
  }
}

.subtitle {
  text-align: center;
  font-size: 18px;
  color: $text-on-light-secondary;
  max-width: 480px;
}

.actions {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.icon {
  margin: 0 auto;
}