$neutral-color-black: #000000;
$neutral-color-neutral-05: #f8f8f8;
$neutral-color-neutral-10: #f0f0f0;
$neutral-color-neutral-15: #EFF0FF;
$neutral-color-neutral-20: #e5e5e5;
$neutral-color-neutral-30: #d4d4d4;
$neutral-color-neutral-40: #afafaf;
$neutral-color-neutral-50: #8f8f8f;
$neutral-color-neutral-60: #676767;
$neutral-color-neutral-70: #545454;
$neutral-color-neutral-80: #363636;
$neutral-color-neutral-90: #161616;
$neutral-color-white: #ffffff;
$primary-color-primary-05: #f7faff;
$primary-color-primary-10: #f2f5ff;
$primary-color-primary-20: #eef0ff;
$primary-color-primary-30: #e2e4f9;
$primary-color-primary-40: #bfc2d6;
$primary-color-primary-50: #a1a3b7;
$primary-color-primary-60: #777a8c;
$primary-color-primary-70: #636677;
$primary-color-primary-80: #444657;
$primary-color-primary-85: #343747;
$primary-color-primary-90: #222534;
$secondary-color-secondary-05: #e1faec;
$secondary-color-secondary-10: #b5f2d0;
$secondary-color-secondary-20: #7deab2;
$secondary-color-secondary-30: #12e192;
$secondary-color-secondary-40: #00d878;
$secondary-color-secondary-50: #00ce61;
$secondary-color-secondary-60: #00be56;
$secondary-color-secondary-70: #00aa49;
$secondary-color-secondary-80: #00983d;
$secondary-color-secondary-90: #007727;
$tertiary-color-tertiary-05: #fff3df;
$tertiary-color-tertiary-10: #ffe0b0;
$tertiary-color-tertiary-20: #ffcb7d;
$tertiary-color-tertiary-30: #ffb648;
$tertiary-color-tertiary-35: #FFB548;
$tertiary-color-tertiary-40: #ffa51c;
$tertiary-color-tertiary-50: #ff9600;
$tertiary-color-tertiary-60: #fb8a00;
$tertiary-color-tertiary-70: #f57a00;
$tertiary-color-tertiary-80: #ef6a00;
$tertiary-color-tertiary-90: #e74e00;
$red-color-red-20: #e95a5a;
$facebook: #0866ff;
$linkedin: #0a66c2;
$youtube: #f00;
$background-background: $primary-color-primary-90;
$borders-border-01: $primary-color-primary-05;
$borders-border-02: $primary-color-primary-30;
$borders-border-03: $primary-color-primary-40;
$borders-border-04: $primary-color-primary-50;
$borders-border-05: $primary-color-primary-60;
$borders-border-06: $primary-color-primary-70;
$graph-primary-01: $secondary-color-secondary-40;
$graph-primary-02: $secondary-color-secondary-20;
$graph-secondary-01: $tertiary-color-tertiary-40;
$graph-secondary-02: $tertiary-color-tertiary-20;
$icon-on-dark-primary: $neutral-color-neutral-05;
$icon-on-dark-secondary: $neutral-color-neutral-20;
$icon-on-dark-tertiary: $neutral-color-neutral-50;
$icon-on-light-primary: $neutral-color-neutral-90;
$icon-on-light-secondary: $neutral-color-neutral-80;
$icon-on-light-tertiary: $neutral-color-neutral-60;
$surfaces-colored-surface-01: $secondary-color-secondary-40;
$surfaces-colored-surface-02: $secondary-color-secondary-20;
$surfaces-colored-surface-03: $tertiary-color-tertiary-40;
$surfaces-colored-surface-04: $tertiary-color-tertiary-20;
$surfaces-dark-surface-01: $primary-color-primary-85;
$surfaces-dark-surface-02: $primary-color-primary-80;
$surfaces-dark-surface-03: $primary-color-primary-70;
$surfaces-dark-surface-04: #484a5e;
$surfaces-light-surface-01: $primary-color-primary-05;
$surfaces-light-surface-02: $primary-color-primary-30;
$surfaces-light-surface-03: $primary-color-primary-40;
$text-on-dark-primary: $neutral-color-neutral-05;
$text-on-dark-secondary: $neutral-color-neutral-30;
$text-on-dark-tertiary: $neutral-color-neutral-40;
$text-on-light-primary: $neutral-color-neutral-90;
$text-on-light-secondary: $neutral-color-neutral-80;
$text-on-light-tertiary: $neutral-color-neutral-60;

$dot-map-color-1: #f57a00;
$dot-map-color-2: #00aa49;
$dot-map-color-3: #C34CC6;
$dot-map-color-4: #676767;

$light-grey-01: #F2F2F2;
$feedback-color-error: #a92a1c;
$feedback-color-error-light: #ffdede;
$feedback-color-success: #198431;
$feedback-color-success-light: #d5f6dc;
$feedback-color-info: $neutral-color-neutral-90;
$feedback-color-info-light: $primary-color-primary-05;
$feedback-color-warning: $tertiary-color-tertiary-80;
$feedback-color-warning-light: $tertiary-color-tertiary-10;

$border-color-secondary-30: #555869;

:export {
  /* stylelint-disable */
  Primary: $tertiary-color-tertiary-40;
  PrimaryHover: $tertiary-color-tertiary-30;
  PrimaryDisabled: $tertiary-color-tertiary-30;
  Secondary: $primary-color-primary-80;
  SecondaryHover: $primary-color-primary-70;
  SecondaryDisabled: $primary-color-primary-70;
  Black: $neutral-color-black;
  LightGrey: $light-grey-01;
  White: $neutral-color-white;
  SecondaryText: $neutral-color-neutral-05;
  PrimaryText: $neutral-color-neutral-90;
  Error: $feedback-color-error;
  /* stylelint-enable */
}