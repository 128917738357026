@import "../../../../styles";

.container {
  display: flex;
  align-items: baseline;

  .title {
    color: $text-on-light-tertiary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 4px;
  }

  .subtitle {
    color: $text-on-light-secondary;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &.hidden {
      filter: blur(3px);
    }
  }
}

