@use '~@carbon/react';

@import './styles';


:root {
  overscroll-behavior: none;
  scrollbar-width: thin;
  scrollbar-color: $primary-color-primary-50 transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $primary-color-primary-50 transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Figtree', sans-serif !important;
}

* ::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  margin-left: 2px;
}

* ::-webkit-scrollbar-track {
  width: 8px;
  border-radius: 6px;
  background-color: transparent;
}

* ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: $primary-color-primary-50;
}

#root {
  width: 100vw;
  height: 100%;
  max-width: 100%;
}

body {
  margin: 0;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

/// Remove overrides once Carbon bugs are fixed upstream.
/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.cds--content {
  margin-top: 3rem;
  background: var(--cds-background);
}

.cds--label {
  font-size: 14px;
  color: #b6b8c6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-border-radius: 6px !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: $text-on-light-primary !important;
  -webkit-box-shadow: none !important;
}

/* Cookiebot overrides */
#CybotCookiebotDialog * {
  font-family: 'Figtree', sans-serif !important;
}

#CybotCookiebotDialog {
  border-radius: 0px !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border-radius: 0px !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerButton {
  display: inline-flex !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
  color: $neutral-color-neutral-90 !important;
  background-color: $tertiary-color-tertiary-40 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}