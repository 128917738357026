@import '../../../styles';

.container {
	animation: $appear-default-animation;

	.meta {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 4px;

		.date {
			color: $neutral-color-neutral-60;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}

		.dot {
			margin-bottom: 2px;
		}
	}

	.content {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 16px;
		padding: 24px;
		background-color: $neutral-color-white;
		box-shadow: $box-shadow-default;

		@media (min-width: $md) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		.body {
			.title {
				color: $neutral-color-neutral-80;
				font-size: 18px;
				font-weight: 500;
				line-height: 24px;
				margin-bottom: 4px;
			}

			.description {
				color: $neutral-color-neutral-90;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				white-space: pre-line;
			}
		}

		.link {
			flex-shrink: 0;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			text-align: center;
			color: $neutral-color-neutral-90;
			text-decoration: none;
			padding: 10px 24px;
			border: 1px solid $tertiary-color-tertiary-40;
		}
	}
}