@import '../../styles';

.container {
  margin-top: -$header-height-mobile;
  width: 100%;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);
  background-image: url('../../assets/dashboard-overlay-mobile.png');
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
    margin-top: -$header-height;
    background-image: url('../../assets/dashboard-overlay.png');
  }

  .overlay {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 120px;
    background: linear-gradient(
      180deg,
      rgba($primary-color-primary-90, 0.6) 0%, $neutral-color-white 100%);
  }
}
