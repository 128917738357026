@import '../../../styles/index';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title,
  .subtitle {
    white-space: pre-line;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 26px;
    color: $neutral-color-neutral-90;
  }

  .subtitle {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $neutral-color-neutral-80;
    margin-bottom: 26px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 12px;

    @media (min-width: $lg) {
      gap: 24px;
      flex-direction: row;
      margin: 0 auto;
    }

    .cta {
      max-width: 250px !important;
      width: 100% !important;
    }
  }
}