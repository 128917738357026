@import "../../../../styles";

.card {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: $text-on-light-primary;
  }
}

.background {
  position: absolute;
  filter: invert(1);
  height: 100%;
  max-height: 336px;
  max-width: 600px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../../../assets/map.png');
}