@import '../../../styles';

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.container {
  position: relative;
  max-height: 1000px;
  margin-bottom: 24px;
  transition: all 300ms ease-out;
  animation: slideIn 300ms ease-out forwards;

  @media (min-width: $md) {
    margin-bottom: 32px;
  }

  .suggestions-container {
    mask-image: linear-gradient(to right,
        transparent,
        rgb(51, 55, 71) 1%,
        rgb(51, 55, 71) 99%,
        transparent);

    @media (min-width: $md) {
      mask-image: linear-gradient(to right,
          transparent,
          rgb(51, 55, 71) 1%,
          rgb(51, 55, 71) 99%,
          transparent);
    }
  }

  .stepper {
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: center;

    .dot {
      padding: 0;
      height: 3px;
      width: 40px;
      margin: 0 5px;
      border-radius: 2px;
      background-color: $primary-color-primary-30;
    }

    .active-dot {
      background-color: $primary-color-primary-85;
    }
  }

  .button {
    height: auto;
    width: auto;
    z-index: 1;
    right: -26px;
    left: unset;
    color: $neutral-color-neutral-90;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;

    &:disabled {
      color: $neutral-color-neutral-90;
      opacity: 0.35;
    }

    &:first-of-type {
      left: -26px;
      right: unset;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }
}

.suggestions {
  display: grid;
  padding: 16px 4px;
  grid-template: 1fr 1fr / 1fr;
  gap: 16px;
  height: 100%;

  @media (min-width: $md) {
    padding: 24px 6px;
    gap: 32px;
    grid-template: 1fr 1fr 1fr / 1fr 1fr;
  }
}