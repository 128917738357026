@import '../../styles';

.card {
  position: relative;
  padding: 16px;
  border-radius: 0;

  @media (min-width: $lg) {
    padding: 24px;
  }
}

.drag-icon {
  top: 18px;
  z-index: 1;
  right: 36px;
  opacity: 1;
  position: absolute;

  @media (min-width: $md) {
    top: 22px;
    right: 46px;
  }

  &:hover {
    opacity: 0.7;
    cursor: grab;
  }

  path {
    fill: $icon-on-light-primary;
  }
}

.more-icon {
  top: 16px;
  z-index: 1;
  right: 16px;
  opacity: 1;
  position: absolute;

  @media (min-width: $md) {
    top: 20px;
    right: 24px;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.thumb-icon {
  margin-left: 8px;
  opacity: 1;
  transition: all 300ms ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: all 300ms ease-out;
  }

  path {
    fill: $icon-on-light-primary;
  }
}

.paper {
  border-radius: 0 !important;
  background: $neutral-color-white !important;
  overflow: visible !important;
  border: 1px solid $borders-border-02;

  .menu-item {
    padding: 8px 0;
    opacity: 1;
    display: flex;
    background: transparent;
    color: $text-on-light-primary;
    transition: all 300ms ease-out;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
      background: transparent;
      transition: all 300ms ease-out;
    }

    &:active,
    &:focus {
      background: transparent;
    }

    .option-label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &.disabled {
      opacity: 0.5;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        cursor: default;
        background: transparent !important;
      }
    }

    &.inactive:not(.disabled) {
      &:hover {
        opacity: 1;
        cursor: default;
      }
    }
  }
}

.menu {
  padding: 8px 16px !important;
}

.modal {
  max-width: 320px !important;

  @media (min-width: $md) {
    max-width: 500px !important;
  }
}