@import '../../styles';

.title,
.subtitle {
  white-space: pre-line;
}

.title {
  justify-content: center !important;
  text-align: center;
}

.subtitle {
  text-align: left;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 24px;
  }

  .button {
    width: 100%;

    @media (min-width: $md) {
      flex-direction: row;
      width: 50%;
    }
  }
}
