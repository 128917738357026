@import '../../../styles';

.container {
  display: flex;
  flex-direction: column;
  border: 0.5px solid $borders-border-02;
  background: $surfaces-light-surface-01;
  max-width: 400px;

  .title-container {
    gap: 8px;
    display: flex;
    padding: 24px 24px 0;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      max-width: 246px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $text-on-light-primary;
    }
  }

  .download {
    cursor: pointer;
  }

  .skeleton {
    width: 70%;
    font-size: 22px;
    border-radius: 0 !important;

    &.small {
      width: 30%;
      font-size: 14px;
    }
  }

  .big-numbers {
    padding: 0 24px;
    gap: 24px;
    display: flex;
    margin: 12px 0 24px;
  }

  .big-number {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    background: $neutral-color-white;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.08);

    .label {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      color: $text-on-light-tertiary;
    }

    .value {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: $text-on-light-primary;
    }
  }

  .image {
    padding: 0 24px;
    height: 240px;
    width: 100%;
    margin-bottom: 24px;
  }

  .footer-container {
    padding: 16px 24px 16px;
    margin-top: auto;
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: $text-on-light-tertiary;
    border-top: 0.5px solid $borders-border-02;
  }
}