@import '../../styles';

.container {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px;
  border-radius: 0;
  border: 1px solid $borders-border-02;
  background: $surfaces-light-surface-01;

  @media (min-width: $md) {
    padding: 8px;
  }

  .tab {
    border-radius: 0;
    display: flex;
    padding: 4px 14px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $text-on-light-tertiary;
    transition: all 300ms ease-out;

    &:hover {
      cursor: pointer;
      color: $text-on-light-secondary;
      transition: all 300ms ease-out;
    }

    &.active {
      transition: all 300ms ease-out;
      color: $text-on-light-primary;
      background-color: $surfaces-colored-surface-03;

      &:hover {
        transition: all 300ms ease-out;
        cursor: pointer;
        color: $surfaces-dark-surface-03;
      }
    }
  }
}