@import '../../styles';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px;
  border-radius: 0;
  border: 1px solid $borders-border-02;
  background-color: $neutral-color-white;

  @media (min-width: $xl) {
    gap: 2px;
    min-width: 240px;
    width: fit-content;
  }

  :global {
    .MuiFormLabel-root {
      color: $text-on-light-secondary;
      text-transform: capitalize;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .MuiInputBase-root {
      --value-color: transparent;
      background-color: transparent;

      width: 100%;
      height: 24px;

      &:before {
        content: '';
        z-index: 1;
        display: inline-block;
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 0;
        background-color: var(--value-color);
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        border: none;
        outline: none;

        input,
        fieldset {
          border: none;
          outline: none;
        }
      }

      &.Mui-focused {
        fieldset.MuiOutlinedInput-notchedOutline {
          border: none;
          outline: none;
        }
      }

      .MuiSvgIcon-root {
        display: none;
      }

      .MuiSelect-select {
        position: relative;
        border: none;
        padding: 0 !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $text-on-light-secondary;

        @media (min-width: $md) {
          font-size: 16px;
          line-height: 24px;
        }

        &:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          right: 0;
          top: 0;
          background-image: url('../../assets/ChevronIcon.svg');
          background-position: center;
          background-repeat: no-repeat;
        }

        &[aria-expanded='true'] {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.menu {
  :global {
    .MuiPaper-root {
      width: calc(100% - 88px);
      margin: 0 auto;
      transform: translateY(23px) !important;
      border-radius: 0;
      background-color: $neutral-color-white;
      box-shadow: none;
      border: 1px solid $borders-border-02;

      @media (min-width: $md) {
        width: 100%;
        max-width: 240px !important;
      }

      .MuiList-root {
        padding: 16px 0;
        background-color: $neutral-color-white;

        li {
          min-height: 0 !important;
          background-color: $neutral-color-white;
        }

        .MuiButtonBase-root {
          color: $text-on-light-secondary;
          padding: 0 0 0 32px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 4px;

          &:hover {
            background-color: $borders-border-01;
          }

          &.Mui-selected {
            background-color: $borders-border-02;
          }
        }

        .MuiListSubheader-root {
          color: $text-on-light-secondary;
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-left: 16px;
          margin-top: 16px;
          margin-bottom: 11px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}