@import '../../../styles';

.rows {
  margin-top: 16px;

  .title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-on-light-secondary;
  }

  .history-row {
    margin-top: 16px;

    .title {
      font-weight: 700;
    }
  }

  .indicator-container {
    width: 32px;
    height: 100%;
    flex-shrink: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .indicator {
      width: 6px;
      height: 6px;
      background: $tertiary-color-tertiary-40;
    }
  }

  .row {
    gap: 8px;
    height: 16px;
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &.active {
      .title {
        font-weight: 700;
      }

      .indicator-container {
        visibility: visible;
      }
    }
  }
}