@import '../../styles';

.container {

  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: $neutral-color-neutral-90;
  }

  .values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .value {
      min-width: 72px;
      padding: 7px 12px;
      background-color: $light-grey-01;
      color: $neutral-color-neutral-90;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: right;
    }
  }

  .slider {
    height: 6px;
    margin: 0 auto;
    display: block;
    width: calc(100% - 18px);

    :global {
      .MuiSlider-rail {
        border-radius: 0;
        background-color: $primary-color-primary-05;
        border: 1px solid $primary-color-primary-40;
      }

      .MuiSlider-track {
        background-color: $tertiary-color-tertiary-50;
        border-color: $tertiary-color-tertiary-50;
      }

      .MuiSlider-thumb {
        height: 10px;
        width: 10px;
        border: 0.5px solid $tertiary-color-tertiary-50;
        background-color: $primary-color-primary-05;
        border-radius: 0;
        box-shadow: 0 0 0 3px rgba($tertiary-color-tertiary-50, 0.4);

        &:hover {
          box-shadow: 0 0 0 5px rgba($tertiary-color-tertiary-50, 0.5);
        }

        &:before {
          box-shadow: none;
        }
      }
    }
  }
}