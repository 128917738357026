@import '../../styles';

.tooltip {
  z-index: 1000;
  min-width: 88px;
  max-width: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: $neutral-color-white;
  border: 1px solid $borders-border-02;
}
