@import '../../styles';

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 0.9;
  }

  50% {
    transform: scale(1.7, 1.7);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot {
  position: relative;
  border-radius: 100px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 100px;
    animation: pulsate linear 2s infinite forwards;
  }

  &.small {
    width: 6px;
    height: 6px;
  }

  &.large {
    width: 12px;
    height: 12px;
  }

  &.success {
    background-color: $tertiary-color-tertiary-40;

    &::after {
      background-color: $tertiary-color-tertiary-40;
    }
  }

  &.error {
    background-color: $red-color-red-20;

    &::after {
      background-color: $red-color-red-20;
    }
  }
}