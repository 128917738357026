@import '../../../styles';

.tooltip {
  margin-top: -10px !important;
}

.skeleton {
  font-size: 1rem;
  border-radius: 0;
}

.card {
  animation: $appear-default-animation;
  width: 100%;
  border-radius: 0 !important;
  background: $neutral-color-white;
  box-shadow: $box-shadow-default;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
      gap: 8px;
      display: flex;
      line-height: 0;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: $text-on-light-secondary;

      &:hover {
        cursor: pointer;
        color: $neutral-color-neutral-60;
      }
    }

    .action-icon {
      width: 18px;
      height: 18px;

      path {
        fill: $neutral-color-neutral-80;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  &.archived {
    background-color: $neutral-color-neutral-30;
    opacity: 0.5 !important;
  }
}