@import '../../../styles';

.container {
  .title {
    margin-top: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $text-on-light-tertiary;
  }

  .subtitle {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    white-space: pre-line;
    color: $text-on-light-secondary;
  }

  .button {
    margin-top: 24px;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
  }
}