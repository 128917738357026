@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.map {
  flex: 1;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  animation: appear 4000ms ease-out forwards;
  padding: 24px;

  :global(.mapboxgl-map) {
    overflow: hidden;
    max-height: 400px;
    min-height: 400px;
  }
}

.marker {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.marker-1 {
  background-color: $dot-map-color-1;
}

.marker-2 {
  background-color: $dot-map-color-2;
}

.marker-3 {
  background-color: $dot-map-color-3;
}

.marker-4 {
  background-color: $dot-map-color-4;
}

:global {
  .mapboxgl-control-container {
    top: 17px;
    left: 17px;
    position: absolute;
    z-index: 10;
  }

  .mapboxgl-ctrl-group {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    overflow: visible;
  }

  .mapboxgl-ctrl-group:not(:empty) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .mapboxgl-ctrl-bottom-right,
  a.mapboxgl-ctrl-logo {
    display: none;
  }

  .mapboxgl-ctrl-group>button {
    height: 32px;
    width: 32px;
    background-color: $primary-color-primary-05;
    box-shadow: $box-shadow-default;
    border: 1px solid $primary-color-primary-40;
    cursor: pointer;
  }

  .mapboxgl-ctrl-group>button+button {
    margin-top: 4px;
  }

  .mapboxgl-ctrl-group>button:hover {
    background-color: $primary-color-primary-05;
    opacity: 0.9;
  }

  .mapboxgl-ctrl-group>button:active {
    scale: 1.05;
  }

  .mapboxgl-ctrl-zoom-in {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41NDkyIDEwLjA0OTJDMTcuNTQ5MiA5LjcwNDAyIDE3LjI2OTMgOS40MjQyIDE2LjkyNDIgOS40MjQyTDEwLjY3NDIgOS40MjQyTDEwLjY3NDIgMy4xNzQxOUMxMC42NzQyIDIuODI5MDIgMTAuMzk0MyAyLjU0OTE5IDEwLjA0OTIgMi41NDkxOUM5LjcwMzk5IDIuNTQ5MTkgOS40MjQxNiAyLjgyOTAyIDkuNDI0MTYgMy4xNzQxOUw5LjQyNDE2IDkuNDI0MkwzLjE3NDE2IDkuNDI0MTlDMi44Mjg5OCA5LjQyNDE5IDIuNTQ5MTYgOS43MDQwMiAyLjU0OTE2IDEwLjA0OTJDMi41NDkxNiAxMC4zOTQ0IDIuODI4OTggMTAuNjc0MiAzLjE3NDE2IDEwLjY3NDJMOS40MjQxNiAxMC42NzQyTDkuNDI0MTYgMTYuOTI0MkM5LjQyNDE2IDE3LjI2OTQgOS43MDM5OCAxNy41NDkyIDEwLjA0OTIgMTcuNTQ5MkMxMC4zOTQzIDE3LjU0OTIgMTAuNjc0MiAxNy4yNjk0IDEwLjY3NDIgMTYuOTI0MkwxMC42NzQyIDEwLjY3NDJMMTYuOTI0MiAxMC42NzQyQzE3LjI2OTMgMTAuNjc0MiAxNy41NDkyIDEwLjM5NDQgMTcuNTQ5MiAxMC4wNDkyWiIgZmlsbD0iIzE2MTYxNiIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: 54% 54%;
  }

  .mapboxgl-ctrl-zoom-out {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjkyNDIgOS40MjQxOUMxNy4yNjkzIDkuNDI0MTkgMTcuNTQ5MiA5LjcwNDAyIDE3LjU0OTIgMTAuMDQ5MkMxNy41NDkyIDEwLjM5NDQgMTcuMjY5MyAxMC42NzQyIDE2LjkyNDIgMTAuNjc0MkwzLjE3NDE2IDEwLjY3NDJDMi44Mjg5OCAxMC42NzQyIDIuNTQ5MTYgMTAuMzk0NCAyLjU0OTE2IDEwLjA0OTJDMi41NDkxNiA5LjcwNDAyIDIuODI4OTggOS40MjQxOSAzLjE3NDE2IDkuNDI0MTlMMTYuOTI0MiA5LjQyNDE5WiIgZmlsbD0iIzE2MTYxNiIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: 54% 54%;
  }

  .mapboxgl-marker {
    position: absolute;
    top: 0;
    left: 0;
  }
}