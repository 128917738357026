@import '../../../../styles';

.recharts-brush-texts {
  display: none;
}

.recharts-brush-slide {
  fill: $borders-border-02;
  fill-opacity: 1;
}

.recharts-brush-traveller {
  line {
    display: none;
  }
}

.recharts-brush-traveller:focus,
.recharts-brush-traveller:active {
  outline: none;
}