@import '../../styles';

.footer {
  animation: $appear-default-animation;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;
  padding: 40px 24px;
  max-width: 1040px;
  margin: 0 auto;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 64px;
  }

  @media (min-width: $xl) {
    padding: 40px 0;
  }

  .technical {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @media (min-width: $md) {
      gap: 24px;
      flex-direction: row;
      align-items: center;
    }

    .link {
      color: $text-on-light-primary;
      text-decoration: underline;
      transition: all 300ms ease-out;

      &:hover {
        cursor: pointer;
        text-decoration: none;
        transition: all 300ms ease-out;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: $md) {
      justify-content: flex-end;
      gap: 24px;
    }
  }

  .contact-button {
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-color: $borders-border-02 !important;
  }

  .year {
    display: flex;
    align-items: center;
    color: $text-on-light-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}