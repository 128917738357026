@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .heading {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 45px;
    font-weight: 500;
    line-height: 56px;
    color: $text-on-light-primary;

    &.hidden {
      filter: blur(8px);
      color: $surfaces-dark-surface-03;
    }
  }

  .subtitle {
    color: $text-on-light-tertiary;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}