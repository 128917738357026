@use '../colors.scss';

.overflowed-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflowed-text-multiline {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.flex {
  display: flex;
}

.capitalize {
  text-transform: capitalize;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.hidden {
  visibility: hidden;
}

.not-displayed {
  display: none;
}

.brilliance {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 35px;
    height: 200%;
    left: 0;
    top: 0;
    background-color: #ffcb7d;
    filter: blur(10px);
    animation: brilliance 7s infinite;
    transform-origin: top;
    transform: rotate(45deg);
  }
}