@import '../../styles';

@keyframes appear {
  0% {
    opacity: 0;
    max-height: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    max-height: 3000px;
  }
}

.container {
  position: relative;
  width: 100%;
  height: calc(100vh - $header-height-mobile);
  height: calc(100dvh - $header-height-mobile);
  max-width: 1040px;
  margin: 0 auto;
  padding-top: 16px;

  @media (min-width: $md) {
    height: calc(100vh - $header-height);
    height: calc(100dvh - $header-height);
  }

  .content {
    animation: appear 300ms ease-out forwards;
  }
}

.conversation {
  position: absolute;
  transition: all 1s ease;
  top: 50%;
  left: 0;
  right: 0;
  height: 100%;
  transform: translateY(-50%);
  display: flex;
  padding: 0 32px 12px;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  @media (min-width: $md) {
    margin-bottom: 32px;
  }

  >h1 {
    font-family: 'Figtree', sans-serif !important;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    color: $neutral-color-neutral-90;
    margin-bottom: 24px;

    @media (min-width: $md) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 74px;
    }
  }
}