@import '../../../../styles';

.card {
  gap: 12px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

  &.overflowed {
    mask-image: linear-gradient(to top,
        transparent,
        transparent 6%,
        rgba(51, 55, 71, 0.95) 25%,
        rgba(51, 55, 71, 0.95) 100%,
        transparent);
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text-on-light-tertiary;
  }

  .heading {
    width: calc(100% - 140px);
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: $text-on-light-primary;

      @media (min-width: $md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    @media (max-width: 500px) {
      gap: 12px;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .content {
    max-height: 600px;
    padding-bottom: 64px;
    overflow: auto;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    .row {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;

      .label {
        display: inline-block;
        flex-shrink: 0;
        width: 82px;
        margin-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $text-on-light-tertiary;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        @media (min-width: $md) {
          width: 188px;
        }
      }

      .value {
        display: inline-block;
        max-width: 75%;
        width: 100%;
        color: $text-on-light-primary;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background-color: $borders-border-03;
    margin: 12px 0;
  }

  .details-content {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .note {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-on-light-primary;
    width: 100%;

    &.has-data {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .cells {
    width: 100%;
    display: flex;
    gap: 12px;
    flex-flow: row wrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .type {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }

    &.no-data {
      text-decoration: none;

      &:hover {
        cursor: default;
      }
    }
  }

  .cell {
    width: 128px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: $md) {
      width: 180px;
    }
  }

  .cell-title {
    color: $text-on-light-tertiary;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .cell-value {
    color: $text-on-light-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &.hidden {
      filter: blur(4px);
    }
  }

  .images-container {
    margin-bottom: 12px;
    width: 100%;

    .image-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
      color: $text-on-light-tertiary;
    }

    .images {
      gap: 12px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .image {
        width: 44px;
        height: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        transition: all 300ms ease-out;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: all 300ms ease-out;
        }

        @media (min-width: 520px) {
          width: 74px;
          height: 48px;
        }

        @media (min-width: $md) {
          width: 100px;
          height: 74px;
        }

        @media (min-width: $lg) {
          width: 74px;
          height: 48px;
        }

        @media (min-width: $xl) {
          width: 100px;
          height: 74px;
        }

        @media (min-width: $xxl) {
          width: 120px;
          height: 94px;
        }

        &.hidden {
          filter: blur(6px);
          pointer-events: none;
        }
      }
    }
  }

  .address {
    padding-left: 2px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .address-cell {
      gap: 8px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .address-cell-title {
      color: $text-on-light-tertiary;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .location {
      gap: 2px;
      display: flex;
      align-items: center;
      color: $text-on-light-primary;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      .icon {
        height: 16px;
        width: 16px;

        path {
          fill: $text-on-light-primary;
        }
      }
    }
  }
}

.link {
  padding: 0;
  color: $text-on-light-primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}