@import '../../../styles';

.legend {
  z-index: 10;
  position: absolute;
  bottom: 17px;
  right: 17px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 8px 8px;
  text-align: right;
  background-color: $primary-color-primary-05;
  box-shadow: $box-shadow-default;
  border: 1px solid $primary-color-primary-40;
  margin-left: 17px;
}

.legend-item {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  text-align: right;
  color: $neutral-color-neutral-90;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition:
    opacity 200ms ease-out,
    color 200ms ease-out;
  white-space: nowrap;

  &:hover {
    color: $neutral-color-neutral-50;
  }

  &.hidden {
    opacity: 0.3;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}

.legend-item-1:before {
  background-color: $dot-map-color-1;
}

.legend-item-2:before {
  background-color: $dot-map-color-2;
}

.legend-item-3:before {
  background-color: $dot-map-color-3;
}

.legend-item-4:before {
  background-color: $dot-map-color-4;
}

.legend-item-other:before {
  background-color: $dot-map-color-4;
}