@import '../../styles';

.collapsable-badge {
  display: flex;
  gap: 8px;
  flex-direction: column;

  .badge {
    width: 90px;
    display: flex;
    justify-content: space-between;
    gap: 2px;
    flex-direction: row;
    padding: 2px 8px;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    color: inherit;
    background-color: $surfaces-dark-surface-02;
    transition: background-color 400ms ease;

    &:hover {
      cursor: pointer;
      background-color: $surfaces-dark-surface-03;
      transition: background-color 400ms ease;
    }

    .icon {
      width: 16px;
      height: 16px;
      transform: rotate(0deg);
      transition: transform 400ms ease;
    }

    &.expanded {
      .icon {
        transform: rotate(-180deg);
        transition: transform 400ms ease;
      }
    }

    &.dark {
      color: $neutral-color-white;
      background-color: $surfaces-dark-surface-02;

      &:hover {
        background-color: $surfaces-dark-surface-03;
      }

      .icon {
        path {
          fill: $neutral-color-white;
        }
      }
    }

    &.light {
      color: $text-on-light-primary;
      background-color: $primary-color-primary-30;

      &:hover {
        background-color: $primary-color-primary-40;
      }

      .icon {
        path {
          fill: $text-on-light-primary;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    color: inherit;
  }

  .item {
    font-size: 14px;
    line-height: 20px;
  }
}