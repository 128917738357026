@import '../../../styles';

.switch-container {
  display: flex;
  justify-content: center;
  margin: 24px 0 16px;

  @media (min-width: $md) {
    margin: 32px 0 40px;
  }
}

.badge {
  padding: 2px 8px;
  background-color: $tertiary-color-tertiary-20;
  color: $neutral-color-neutral-90;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.plans {
  margin: 0 auto 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  @media (min-width: $lg) {
    max-width: 1040px;
    flex-direction: row;
    align-items: stretch;
  }
}