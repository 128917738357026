@import '../../../../styles';

.stepper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $primary-color-primary-30;

  @media (min-width: $lg) {
    flex-direction: row;
    width: calc(100% - 33px);
    border-bottom: none;
  }

  .step {
    position: relative;
    padding: 12px 16px;
    border-top: 1px solid $primary-color-primary-30;
    border-left: 1px solid $primary-color-primary-30;
    border-right: 1px solid $primary-color-primary-30;

    @media (min-width: $lg) {
      padding: 12px 0 12px 16px;
      border-bottom: 1px solid $primary-color-primary-30;
      border-left: none;
      border-right: none;
      margin-right: 48px;
    }

    .step-border {
      display: none;
    }

    @media (min-width: $lg) {
      .step-border {
        display: block;
        position: absolute;
        right: -48px;
        top: -1px;
        height: calc(100% + 2px);
        width: 48px;
        border-top: 1px solid $primary-color-primary-30;
        border-bottom: 1px solid $primary-color-primary-30;
        background-size: auto 102%;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgMzQgMTI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxQzEzLjQ5NjggMjUuNjAzIDMzIDY0IDMzIDY0QzMzIDY0IDEzLjQ5NjggMTAyLjM5NyAxIDEyNyIgc3Ryb2tlPSIjRTJFNEY5Ii8+Cjwvc3ZnPgo=);
      }

      &:first-child {
        border-left: 1px solid $primary-color-primary-30;
      }

      &:last-child {
        margin-right: 0;

        .step-border {
          right: -47px;
          border-top: none;
          border-bottom: none;
        }
      }
    }

    .step-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .step-index {
        font-family: "Rufina", serif !important;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: $tertiary-color-tertiary-35;
      }

      .step-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: $neutral-color-neutral-90;
      }

      .step-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $neutral-color-neutral-60;
      }
    }
  }
}