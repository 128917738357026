@import '../../styles';

:global(.MuiTooltip-popper) .tooltip {
    padding: 4px 8px;
    color: $neutral-color-neutral-90;
    background-color: $neutral-color-white;
    box-shadow: $box-shadow-default;
    border: 1px solid $primary-color-primary-30;
    max-width: 300px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 0;
}