@import '../../../../styles';

.card {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  align-items: flex-start;
  padding: 24px;
  color: $text-on-light-primary;
  width: 100%;
  border: 1px solid $neutral-color-neutral-20;

  @media (min-width: $lg) {
    padding: 40px;
  }

  .description-container {
    margin-bottom: 0;
  }

  .description,
  .subdescription {
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    color: $text-on-light-primary;
    margin-bottom: 8px;
  }

  .subdescription {
    white-space: pre-line;
    color: $text-on-light-tertiary;
  }

  .stepper {
    width: 100%;
    margin: 24px 0;

    @media (min-width: $lg) {
      margin: 40px 0;
    }
  }

  .header-container {
    display: flex;
    font-weight: 400;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    margin-bottom: 16px;
    justify-content: space-between;

    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;

      @media (min-width: $lg) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .button {
    width: 100%;
    max-width: 500px;
    font-size: 14px;
    padding: 12px 24px;
    margin-top: 24px;

    @media (min-width: $md) {
      width: fit-content;
      font-size: 16px;
      line-height: 24px;
      margin-top: 32px;
    }
  }
}