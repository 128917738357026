@import '../../styles';

.container {
  animation: $appear-default-animation;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    margin-top: 32px;
    min-height: calc(100vh - $header-height - 32px);
    min-height: calc(100dvh - $header-height - 32px);
  }

  .content {
    position: relative;
    height: 100%;
    padding: 40px 16px 0;
    max-width: 1040px;
    margin: 0 auto;

    @media (min-width: $lg) and (max-width: $xl)  {
      max-width: 890px;
    }

    @media (min-width: $md) {
      padding: 0 16px 0;
    }

    @media (min-width: $xl) {
      padding: 0;
    }

    .title {
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      color: $text-on-light-primary;
      margin-bottom: 32px;

      @media (min-width: $md) {
        padding-left: calc(200px + 86px);
        font-size: 45px;
        line-height: 56px;
        margin-bottom: 48px;
      }

      @media (min-width: $lg) {
        padding-left: calc(200px + 107px);
      }
    }
  }
}