@import "../../styles";

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: $neutral-color-neutral-90;

  .back {
    cursor: pointer;
    transition: transform .3s ease;

    &:hover {
      transform: translateX(-2px);
    }
  }
}