@import '../../../styles';

.container {
  .subheading {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
    color: $text-on-light-primary;
  }
}
