@import '../../../../styles';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 24px;
  width: 100%;
  background: $neutral-color-white;
  color: $text-on-light-primary;
  border: 1px solid $neutral-color-neutral-20;

  @media (min-width: $lg) {
    width: 50%;
    padding: 40px 32px;
    border-radius: 0;
    border-width: 1px 0 1px 1px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 8px;

    @media (min-width: $lg) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .description-container {
    height: auto;
    margin-bottom: 24px;

    @media (min-width: $lg) {
      height: 220px;
    }

    @media (min-width: $xl) {
      margin-bottom: 0;
    }
  }

  .description,
  .subdescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .description {
    color: $text-on-light-tertiary;
    height: auto;
    margin-bottom: 8px;
  }

  .subdescription {
    color: $text-on-light-primary;
  }

  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .feature {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &-icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
    }
  }
}