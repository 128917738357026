@import '../../styles';

.container {
  animation: $appear-default-animation;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  .content {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 24px 32px 40px;
    max-width: 1040px;
    margin: 0 auto;

    @media (min-width: $lg) {
      margin-bottom: 66px;
    }

    @media (min-width: $lg) {
      margin-bottom: 66px;
    }

    @media (min-width: $lg) and (max-width: $xl)  {
      max-width: 890px;
    }

    .search {
      margin-bottom: 32px;
    }

    .checkbox-label {
      margin-left: 0 !important;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
      color: $text-on-light-tertiary;
    }

    .checkbox-container {
      padding-left: 0;
      color: $primary-color-primary-60;

      &:hover {
        background: transparent !important;
      }

      rect {
        clip-path: inset(0 0 0px 0) !important;
        fill: $neutral-color-white !important;
        stroke: $tertiary-color-tertiary-40;
      }

      &:global(.Mui-checked) {
        rect {
          fill: $tertiary-color-tertiary-40 !important;
        }
      }
    }

    .cards {
      margin: 16px 0 32px;

      .group {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .date {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $text-on-light-tertiary;
          margin-bottom: 4px;
        }
      }
    }

    .empty-state {
      animation: $appear-default-animation;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 24px;
      background-color: $neutral-color-white;
      box-shadow: $box-shadow-default;

      .empty-state-title {
        margin-top: 16px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: $neutral-color-neutral-80;
      }

      .empty-state-description {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $neutral-color-neutral-90;
      }
    }

    .show-more {
      display: block;
      width: fit-content;
      margin: 0 auto;
      height: 40px;
      padding: 10px 24px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}