@import '../../../styles';

.container {
  width: calc(100% - 12px);
  padding: 12px 36px 12px 16px;
  background-color: $neutral-color-white;
  box-shadow: $box-shadow-default;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @media (min-width: $md) {
    padding: 12px 42px 12px 24px;
    width: calc(100% - 64px);
  }

  .close {
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;

    @media (min-width: $md) {
      right: 18px;
    }
  }

  .content {
    >* {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    strong {
      font-weight: 600;
      color: $neutral-color-neutral-90;
    }

    span {
      color: $neutral-color-neutral-80;
    }

    a {
      font-weight: 600;
      color: $tertiary-color-tertiary-70;
      text-decoration: none;
      transition: opacity .2s ease;

      &:hover {
        opacity: .7;
      }
    }
  }
}