@import '../../../../../styles';

.images-container {
  margin-bottom: 12px;
  width: 100%;
  padding-bottom: 48px;

  .image-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;
    color: $text-on-light-tertiary;
  }

  .images {
    gap: 12px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    .image {
      width: 44px;
      height: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
      transition: all 300ms ease-out;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: all 300ms ease-out;
      }

      @media (min-width: 520px) {
        width: 74px;
        height: 48px;
      }

      @media (min-width: $md) {
        width: 100px;
        height: 74px;
      }

      @media (min-width: $lg) {
        width: 74px;
        height: 48px;
      }

      @media (min-width: $xl) {
        width: 100px;
        height: 74px;
      }

      @media (min-width: $xxl) {
        width: 120px;
        height: 94px;
      }
    }
  }
}