@import '../../../styles';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & *:focus {
    outline: none;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
  color: $text-on-light-primary;
  margin-right: 48px;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
  }
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-on-light-secondary;
}

.pie-chart-container {
  width: 100%;
  height: 220px;

  @media (min-width: $lg) {
    width: 50%;
  }
}

.chart-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }

  .rows {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.hidden {
      filter: blur(3px);
    }

    @media (min-width: $lg) {
      width: 50%;
    }

    .rows-title {
      font-size: 14px;
      font-weight: 400;
      color: $text-on-light-tertiary;
    }

    .row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      display: flex;
      width: 100%;
      padding-bottom: 8px;

      .label-container {
        max-width: 90%;
        display: flex;
        align-items: center;
      }

      .label {
        color: $text-on-light-secondary;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }

      .value {
        color: $text-on-light-primary;
        font-size: 14px;
        font-weight: 400;

        &.hidden {
          filter: blur(3px);
        }
      }

      .color {
        width: 12px;
        height: 10px;
        display: inline-block;
        margin-right: 4px;
        border-radius: 4px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $borders-border-02;
      }
    }
  }
}

.pie-chart {
  margin: 0 auto;
}

.pie {
  overflow: visible;
}

.total-value {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}