@import '../../../../styles';

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.canvas {
  display: flex;
  align-items: center;
  height: 280px;

  mask: linear-gradient(to top,
      transparent 10%,
      $background-background 15% 90%,
      transparent 95%),
    linear-gradient(to left,
      transparent 5%,
      $background-background 15% 90%,
      transparent 95%);
  mask-size: 110% 110%;
  mask-position: center;
  mask-composite: intersect;
  mask-repeat: no-repeat;

  &.hidden {
    filter: blur(3px);
    pointer-events: none;
  }

  span {
    display: block;
    width: 80%;
    flex-shrink: 0;
    margin: 0 auto;
    height: 14px;
    border-radius: 14px;
    background-color: $primary-color-primary-80;

    >span {
      display: block;
      background-color: $secondary-color-secondary-20;
      width: 100%;
    }
  }

  @media (min-width: $lg) {
    height: 430px;
  }

  canvas {
    animation: appear 300ms 300ms ease-out forwards;
  }
}