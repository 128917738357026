@import "../../../../styles";

.chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 430px;

  &.hidden {
    filter: blur(3px);
  }
}

.pie-chart {
  margin: 0 auto;
}

.pie {
  overflow: visible;
}

.total-value {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}