@import '../../styles';


.container {
  animation: $appear-default-animation;
  min-height: calc(100vh - $header-height-mobile);
  min-height: calc(100dvh - $header-height-mobile);

  @media (min-width: $md) {
    min-height: calc(100vh - $header-height);
    min-height: calc(100dvh - $header-height);
  }

  .content {
    position: relative;
    height: 100%;
    padding: 40px 16px;
    max-width: 1040px;
    margin: 0 auto;

    @media (min-width: $lg) and (max-width: $xl)  {
      max-width: 890px;
    }

    @media (min-width: $lg) {
      padding: 32px 16px;
    }

    @media (min-width: $xl) {
      padding: 32px 0px;
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 24px;
      background-color: $neutral-color-white;
      box-shadow: $box-shadow-default;

      .empty-state-icon {
        width: 52px;
        height: 52px;
        margin-bottom: 16px;

        path {
          fill: $neutral-color-neutral-60;
        }
      }

      .empty-state-title {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: $neutral-color-neutral-80;
      }

      .empty-state-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $neutral-color-neutral-90;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .show-more {
      height: 40px;
      display: flex;
      width: fit-content;
      margin: 40px auto 0;
    }
  }
}