@import '../../../../styles';

.container {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 16px 24px;
  gap: 16px;
  border-top: 1px solid $primary-color-primary-30;

  .action {
    height: 40px;
    width: calc(50% - 8px);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:last-of-type {
      width: 100%;
    }
  }
}