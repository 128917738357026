@import '../../../../styles';

.modal {
  width: 100%;
  position: relative;
  padding: 0 !important;
  border-radius: 0 !important;
  overflow: visible !important;
  max-width: 320px !important;
  background: transparent !important;
  box-shadow: none !important;

  @media (min-width: $md) {
    max-width: 600px !important;
  }

  .content {
    gap: 16px;
    display: flex;
    flex-direction: column;

    @media (min-width: $md) {
      gap: 24px;
    }
  }
}

.button {
  height: auto;
  width: 34px;
  z-index: 1;
  right: -46px;
  color: $neutral-color-neutral-90 !important;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  position: absolute !important;

  &:disabled {
    color: $neutral-color-neutral-90;
    opacity: 0.35;
  }

  &:first-of-type {
    left: -46px;
    top: 50%;
  }

  svg {
    height: 40px;
    width: 40px;
  }
}

.modal-content {
  position: relative;
}

.slide {
  display: flex;
  align-items: center;
}

.zoomed-image {
  height: auto;
  width: 100%;
  max-width: 320px;
  display: block;
  background-color: $neutral-color-white;

  @media (min-width: $md) {
    max-width: 600px;
  }
}