@import '../../styles';

.container {
  width: 100%;
  margin-top: -$header-height-mobile;
  height: 100vh;
  height: 100dvh;

  @media (min-width: $md) {
    margin-top: -$header-height;
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba($primary-color-primary-90, 0.6) 0%,
      $primary-color-primary-90 100%
    );
  }

  .title {
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    max-width: 280px;
  }
}
